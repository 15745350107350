//**
// Navigation
//**

$blue: #005F90;
$lightblue: #1ca7e0;
$darkblue: #004161;
$white: #ffffff;
$normal-font: 13px;
$semi-big-font: 14px;

%highlight-background {
  background-color: $lightblue;
  color: $white;
}
@mixin hover-background-blue {
  @include transition(all 0.5s ease-in-out);
  &:hover {
    @extend %highlight-background;
  }
}

nav {
  a {
    //display: inline-block;
    //padding: 10px 30px;
  }
}

#menu {
  position: relative;
  padding: 0;
  padding-right: 65px;
  nav {
    float: right;
    ul {
      list-style: none;
      position: relative;
      float: left;
      padding: 0;
      margin-top: 80px;
      margin-bottom: 30px;
      margin-right: 0;
      li {
        position: relative;
        float: left;
        margin: 0;
        padding: 0;
        cursor: pointer;
        a {
          color: $c-04;
          display: block;
          text-decoration: none;
          @extend %f-b;
          padding: 10px 15px;
          border-radius: 5px;
          text-transform: uppercase;
          //@include hover-background-blue;
          //&.active {
          //  @extend %highlight-background;
          //}
          &:hover {
            color: $c-03;
          }
        }
        &:hover > ul {
          display: block;
        }
        ul {
          color: white;
          display: none;
          position: absolute;
          top: 100%;
          right: 0;
          padding: 0;
          z-index: 99999;
          padding-top: 10px;
          margin: 0;
          li {
            float: none;
            width: 280px;
            a {
              border-radius: 0;
              background-color: rgba($c-03, 0.9);
              color: $c-05;
              padding: 15px 20px;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-wrap: normal;
              @extend %f-c;
              text-transform: none;
            }
            &:hover > a {
              background-color: $c-02;
              color: $c-05;
            }
            ul {
              top: 0;
              left: 100%;
              padding: 0;
              padding-left: 15px;
              li {
                a {
                  padding: 15px 20px;
                }
              }
            }
          }
        }
      }
    }
  }
  nav.mobile {
    display: none;
  }
}

#random-logo {
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 1;
  width: 20%;
  @media (max-width: 991px) {
    left: 30px;
    top: 10px;
    text-align: left;
    width: 110px;
  }
}

#search-btn {
  position: absolute;
  top: 70px;
  right: 0;
  background: $c-03;
  color: $c-05;
  padding: 18px 20px;
  border-radius: 100%;
  display: inline-block;
  cursor: pointer;
  z-index: 1;
  @include transition(all 0.5s ease);
  .fa {
    width: 10px !important;
  }
  &:hover {
    background: $c-01;
    color: $c-03;
  }
}

#search-bar {
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: none !important;
  border-radius: 0 !important;
  border: none !important;
  color: $c-02;
  @extend %f-m;
  @include placeholder {
    color: $c-02 !important;
    font-family: $proximanova-regular, 'fontAwesome';
    font-size: 24px !important;
  }
  padding-top: 45px;
  background-color: $c-05;
}

#search-bar-mobile {
  box-shadow: none !important;
  border-radius: 0 !important;
  border: none !important;
  margin: 10px 10px 10px 20px;
  width: 100%;
  color: $c-02;
}
