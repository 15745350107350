//@extend-elements
//original selectors
//html, body, #wrap
%extend_1 {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

html {
  @extend %extend_1;
}

body {
  @extend %extend_1;
}

#wrapper {
  @extend %extend_1;
  display: table;
  width: 100%;
  table-layout: fixed;
}

main {
  display: table-row;
}

header {
  display: table-row;
  height: 1px;
}

footer {
  display: table-row;
  height: 1px;
}
.mm-page{
  height: 100%;
}