/* Bourbon */
@import "../../../../../vendor/bower_components/bourbon/app/assets/stylesheets/_bourbon";

@import "variables";

$template: 'uno-v3';
$templateImgFolder: '../../img/#{$template}';
$fontFolder: '../../fonts/webfonts';

/*@include font-face('helvetica_ce_35_thinregular', "#{$fontFolder}/helvetica-webfont");
@include font-face('montserratregular', "#{$fontFolder}/montserrat-bold-webfont");
@include font-face('montserratbold', "#{$fontFolder}/montserrat-regular-webfont");*/

@import '../../shared';
@import 'mixins';
@import 'type';
@import 'sticky-footer';
@import 'col-height';
@import "media";
@import "buttons";
@import "tables";
@import "forms";
@import "alerts";
@import "nav";
@import "other";

.relative {
  position: relative;
}

.none {
  display: none;
}

.no-padding {
  padding: 0;
}

.left-0-padding {
  padding-left: 0;
}

.right-0-padding {
  padding-right: 0;
}

.top-0-padding {
  padding-top: 0;
}

.bottom-0-padding {
  padding-bottom: 0;
}

.no-gutters {
  padding-left: 0;
  padding-right: 0;
}

.dark-blue {
  color: $darkblue;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.title-lg-inline {
  display: inline-block;
  @media (max-width: $big-break) {
    display: block;
  }
}

.title-md-inline {
  display: inline-block;
  @media (max-width: $medium-break) {
    display: block;
  }
}

.center-of-unknown {
  @include centering-the-unknown;
}

#header-content {
  position: relative;
  border-top: 5px solid $c-01;
}

.upper-header {
  background-color: $c-02;
  width: 100%;
  color: $c-05;
  ul {
    display: inline-block;
    margin-bottom: 0;
    list-style: none;
    padding: 20px 0;
    li {
      display: inline;
      div{
        display: inline;
        a {
          color: $c-05;
          @extend %f-a;
        }
      }
      padding: 0 10px;
      position: relative;
      &:after {
        content: '';
        background-color: $c-05;
        position: absolute;
        top: 25%;
        right: -1%;
        width: 1px;
        height: 50%;
      }
      &:last-child:after {
        content: '';
        width: 0;
      }
      @media (max-width: 991px) {
        padding: 0;
        display: block;
        &:after {
          content: '';
          width: 0;
        }
      }
    }
  }
}

.lower-header {
  background-color: $c-05;
  width: 100%;
  color: $c-02;
  position: relative;
  .logo-img {
    position: absolute;
    top: -30px;
    z-index: 9999;
  }
}

.top-header {
  background-color: $almostblack;
  padding: 0;
  color: $white;
  .user-pref {
    float: right;
    &#currency .currency-cont {
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        @include uno-divider($left: 1px);
        padding: 10px;
        * {
          display: inline-block;
          vertical-align: middle;
        }
        a {
          text-transform: uppercase;
          color: white;
          font-size: $tiny-font;
          text-decoration: none;
          line-height: 1;
          @include hover-blue;
        }
        /*end of a*/
        .flag {
          vertical-align: middle;
        }
      }
      /*end of li*/
    }
    /*end of currency*/
    &#search {
      padding: 0 15px;
      line-height: 36px;
      cursor: pointer;
      @include uno-divider(1px, 1px);
      @include hover-background-blue;
      &.active{
        background-color: $color_1;
      }
    }
    /*end of search*/
  }
  /*end of USER-PREF*/
}

.bottom-header {
  #logo {
    margin: 30px 0;
    padding: 0;
  }

}

#menuBtn {
  //position: absolute;
  //top: 45%;
  //right: 30px;
  font-size: 40px;
  text-align: right;
  margin: 45px 0;
  @include transition(all 0.5s ease);
  a {
    color: $c-02 !important;
    &:hover {
      color: $c-01 !important;
    }
  }
}

#banner {
  position: relative;
  @include transition(all 0.5s ease-in-out);
  .slides {
    .slide {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 490px;
      @media (max-width: $medium-break) {
        height: 320px;
      }
      &[href="#"] {
        cursor: default;
      }
    }
    margin-bottom: 0 !important;
  }
  /*end of slides*/
  .slides-dots-absolute {
    position: absolute;
    bottom: 0;
    width: 100%;
    .slides-dots {
      .slick-dots {
        position: relative !important;
        bottom: 0 !important;
        text-align: right !important;
        li {
          button {
            &:before {
              font-size: 16px !important;
              color: $c-05;
              opacity: 1;
            }
          }
          &.slick-active {
            button {
              &:before {
                font-size: 16px !important;
                color: $c-01;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  .descs {
    width: 100%;
    max-width: 620px;
    position: absolute;
    //background-color: $not-so-semi-white;
    padding: 30px;
    bottom: 20px;
    @include transition(opacity 0.5s ease-in-out);
    @media (max-width: $big-break) {
      width: 90%;
    }
    @media (max-width: $medium-break) {
      max-width: none;
    }
    .desc {
      .title {
        text-transform: uppercase;
        color: $c-03;
        line-height: 50px;
        margin-bottom: 5px;
        @extend %f-d;
        background-color: $c-01;
        @media(max-width: 991px) {
          font-size: 20px !important;
          line-height: 25px !important;
        }
        display: inline;
      }
      p {
        text-transform: uppercase;
        color: $c-03;
        @extend %f-e;
        background-color: $c-01;
        line-height: 50px;
        @media(max-width: 991px) {
          font-size: 20px !important;
          line-height: 25px !important;
        }
        display: inline;
      }
      .link {
        display: inline-block;
        background-color: $c-03;
        color: $c-05;
        @extend %f-f;
        text-transform: uppercase;
        padding: 18px 32px;
        border-radius: 30px;
        margin-top: 30px;
        @include transition(all 0.5s ease);
        &:hover {
          background-color: $c-01;
          color: $c-03;
        }
      }
    }
    &.slick-slider {
      .slick-dots {
        bottom: 0;
        left: 0;
        li {
          button {
            width: 10px;
            height: 10px;
            &:before {
              font-size: 10px;
              color: $lightblue;
              @include transition(all 0.5s ease);
              opacity: 0.5;
              width: auto;
              height: auto;
            }
          }

          &.slick-active button:before, & button:hover:before {
            color: $darkblue;
            opacity: 1;
            font-size: 12px;
          }
        }
      }
      .slick-prev:before, .slick-next:before {
        color: $darkblue;
      }
      .slick-slide:focus{
        outline: none;
      }
    }

  }
  /*end of descs*/
}

#inner-banner {
  .slide {
    width: 100%;
    height: 240px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .inner-banner-title {
    text-transform: uppercase;
    color: $c-03;
    @extend %f-e;
    background-color: $c-01;
    line-height: 30px;
    padding: 5px;
    position: absolute;
    top: 65%;
    @media(max-width: 991px) {
      font-size: 20px !important;
      line-height: 25px !important;
    }
    display: inline;
  }
  margin-bottom: 0 !important;
}

#inner-banner-empty-placeholder{
}

#breadcrumb {
  display: none;
  @media(min-width: 768px) {
    display: block;
  }
  padding: 30px 0px;
  @extend %f-a;
  .text {
    color: $c-02;
  }
  .crumb {
    color: $c-02;
    &:after {
      content: '\25B6';
      margin: 0 5px;
      font-size: 10px;
    }
    &:last-child:after {
      content: '';
    }
  }
}

#content {
  //border-top: 5px solid $lightblue;
  .top-content {
    //margin-top: -60px;
  }
  .page-content {
    h1, h2, h3 {
      color: $c-02;
      padding: 15px 0;
      margin: 15px 0 0;
      @extend %f-h;
    }
    h2 {
      //font-size: $huge-font;
    }
    p {
      color: $c-04;
      @extend %f-g;
      //color: $fontblack;
      //font-size: $small-font;
      text-align: justify;
    }
    img {
      max-width: 100%;
      display: block;
    }
    .read-more {
      color: $darkblue;
      font-size: $normal-font;
      @include arrow-gen(right, 3px, $lightblue, $margin: 5px)
    }
    .left-content {
      @include last-child-margin;
      background-color: $white;
    }
    .right-content {
      background-color: $almostwhite;
      @include last-child-margin;
    }
  }
  .bottom-content {
    margin-top: 30px;
  }
}

#news-mini {
  border-top: 15px solid rgba(white, 0.1);
  border-bottom: 15px solid rgba(black, 0.06);
  padding: 20px;
  background-color: $lightblue;
  @include transition(all 0.5s ease-in-out);
  @include centering-the-unknown;
  .title-cont {
    position: relative;
    @include uno-divider($right: 1px, $shadow: $color_2, $highlight: $color_3);
    margin-bottom: 10px;
    @media (max-width: $medium-break) {
      width: 100%;
      border: none;
      box-shadow: none;
      margin-bottom: 15px;
      padding-bottom: 10px;
      @include uno-divider($bottom: 1px, $shadow: $color_2, $highlight: $color_3);
      @include transition(all 0.5s ease-in-out);
    }
    .title {
      font-size: $bigger-font;
      color: white;
      margin-bottom: 10px;
      @media (max-width: $big-break) {
        display: inline-block;
        font-size: $normal-font;
      }
      @media (max-width: $medium-break) {
        display: inline-block;
      }

    }
    .link {
      color: $darkblue;
      display: inline-block;
      font-size: $normal-font;
      @include arrow-gen(right, 3px, white, $margin: 5px);
      @media (max-width: $medium-break) {
        float: right;
      }
    }
  }
  .news {
    @include transition(opacity 0.5s ease-in-out);
    @include uno-divider($right: 1px, $shadow: $color_2, $highlight: $color_3);
    @media (max-width: $big-break) {
      border: none;
      box-shadow: none;
    }
    .slide {
      @include centering-the-unknown;
      .date {
        @extend %f03;
        color: $darkblue;
        text-align: center;
        @media (max-width: $medium-break) {
        }
        .day {
          font-size: $huger-font;
        }
        .month {
          font-size: 14px;
        }
      }
      .desc {
        .title {
          color: $darkblue;
          margin-bottom: 5px;
          text-transform: uppercase;
          @include ellipsis(98%);
        }
        p {
          color: white;
          font-size: $normal-font;
          margin: 0;
        }
        a{
          display: block;
        }
      }
      &:focus {
        outline: none;
      }
    }
  }
  /*end of news*/
  .slide-arrow {
    @include centering-the-unknown;
    .slide-left {
      display: inline-block;
      cursor: pointer;
      font-size: 14px;
      @include hover-color;
      &:hover {
        color: white;
      }
    }
    .slide-right {
      @extend .slide-left;
      margin-left: 5px;
    }
  }
}

//#newsletter {
//  width: 100%;
//  cursor: pointer;
//  text-align: left;
//  border-top: 15px solid rgba(white, 0.1);
//  border-bottom: 15px solid rgba(black, 0.06);
//  background-color: $blue;
//  @include centering-the-unknown;
//  padding: 15px;
//  @include transition(all 0.5s ease-in-out);
//  .col {
//    @include centering-the-unknown;
//    padding: 0;
//    img {
//      display: inline-block;
//      width: auto;
//    }
//  }
//  h3.title {
//    color: $lightblue !important;
//    font-size: 20px;
//    margin: 0 0 5px !important;
//    padding: 0;
//    @media (max-width: $big-break) {
//      font-size: 17px;
//    }
//  }
//  .link {
//    color: white;
//    font-size: $normal-font;
//    display: none;
//    @include arrow-gen(right, 3px, $lightblue, $margin: 5px);
//  }
//  .form-group{
//    display: none;
//    .form-control {
//      width: 100%;
//      height: 34px;
//      font-weight: normal;
//      @extend %f02;
//    }
//    .form-control-feedback{
//      width: auto;
//      height: auto;
//      margin: 10px;
//    }
//  }
//}

.a-banner {
  margin-top: 30px;
}

#get-quote {
  margin-top: 30px;
}

#event-calendar {
  margin-top: 30px;
}

#home-video-cont {
  padding: 0;
  height: 279px;
  a {
    display: block;
    width: 100%;
  }
  .vid {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

#home-customer-value {
  border-top: 15px solid rgba(white, 0.1);
  border-bottom: 15px solid rgba(black, 0.06);
  padding: 20px 0;
  background-color: $lightblue;
  @include transition(all 0.5s ease-in-out);
  .title {
    color: $darkblue;
  }
  img {
    margin-bottom: 15px;
  }
  p {
    color: white;
  }
}

#home-any-last-words {
  margin: 30px 0;
  color: $lightblue;
}

#popUpsContainer{
  display: none;
}

.white-popup.popup {
  padding: 10px;
}

//footer {
//  #footer-content {
//    background-color: $almostblack;
//    border-top: 5px solid $lightblue;
//    padding: 0 0 40px;
//    h4 {
//      color: $lightblue;
//      text-transform: uppercase;
//      font-size: $normal-font;
//      margin-bottom: 10px;
//    }
//
//    .bottom-footer {
//      @include uno-divider($top: 1px, $shadow: $color_4, $highlight: $color_5);
//      padding-top: 15px;
//      .links {
//        color: white;
//      }
//      .site-map {
//        color: white;
//      }
//    }
//  }
//}
//
//.top-footer {
//  background-color: $almostblack;
//  padding-bottom: 30px;
//  h4 {
//    color: $lightblue;
//    text-transform: uppercase;
//    font-size: $normal-font;
//    margin-bottom: 10px;
//  }
//  & > * {
//    padding-top: 20px;
//    &:nth-child(2), &:nth-child(3) {
//      @include uno-divider($right: 1px, $shadow: $color_4, $highlight: $color_5);
//    }
//    &:nth-child(2) {
//      @media (max-width: $big-break) {
//        box-shadow: none;
//        border: 0;
//      }
//    }
//    /*end of nth-child(2)*/
//    &:first-child {
//      @media (max-width: $medium-break) {
//        text-align: left;
//      }
//    }
//    /*end of first-child()*/
//
//  }
//
//  p {
//    font-size: $tiny-font;
//    color: white;
//    margin: 0;
//    line-height: 1.4;
//  }
//  .footer-logo {
//    img {
//      margin-bottom: 10px;
//    }
//  }
//  .hotline {
//    margin-top: 15px;
//    i {
//      color: $lightblue;
//      vertical-align: middle;
//    }
//    .phone-number {
//      display: inline-block;
//      color: white;
//      vertical-align: middle;
//      margin: 0;
//      >a{
//        color: white;
//      }
//    }
//  }
//  .quick-nav {
//    a {
//      display: block;
//      color: white;
//      margin-bottom: 5px;
//      font-size: 12px;
//      &:after {
//        left: 0;
//      }
//      &:hover {
//        text-decoration: underline;
//        &:after {
//          left: 100%;
//        }
//      }
//      i {
//        color: $lightblue;
//        padding: 0 5px;
//      }
//    }
//  }
//  .address {
//    margin-bottom: 10px;
//  }
//}

#subscribe {
  background: linear-gradient(#ffca01, #f9b418);
  overflow: hidden;
  @media (max-width: 991px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  img {
    margin: -20px auto;
    @media (max-width: 991px) {
      margin: auto;
      display: none;
    }
  }
  .text-1 {
    color: $c-02;
    @extend %f-e;
    margin: 0;
    @media (max-width: 991px) {
      margin-top: 15px;
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {

    }

  }
  .text-2 {
    color: $c-05;
    @extend %f-j;
    margin: 0;
  }
  #newsletter {
    display: inline-block;
    background-color: $c-03;
    color: $c-05;
    @extend %f-f;
    text-transform: uppercase;
    padding: 18px 32px;
    border-radius: 30px;
    margin-top: 30px;
    @include transition(all 0.5s ease);
    &:hover {
      background-color: $c-01;
      color: $c-03;
    }
    cursor: pointer;
  }
}

footer {
  //background-color: $c-05;
  #footer-latar {
    .title {
      color: $c-02;
      @extend %f-h;
      text-transform: uppercase;
      border-bottom: 2px solid $c-01;
      margin-top: 50px;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
    li {
      list-style: none;
      padding-bottom: 15px;
      a {
        @extend %f-a;
        color: $c-04;
        &:hover {
          color: $c-03;
        }
      }
    }
    .lower-footer {
      padding-top: 65px;
      padding-bottom: 30px;
      .sitemap {
        color: $c-04;
        @extend %f-l;
        text-transform: uppercase;
        a {
          color: $c-04;
          &:hover {
            color: $c-04;
          }
        }
        display: inline-block;
      }
      #newsletter {
        display: inline-block;
        cursor: pointer;
      }
      .logo {
        cursor: pointer;
        display: inline-block;
        margin: 7px;
        border: 2px solid transparent;
        border-radius: 50%;
        background-color: $c-05;
        &:hover {
          border-color: $c-01;
        }
      }
    }
    #footer-button {
      color: $c-05;
      background: $c-03;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      font-size: 17px;
      text-align: center;
      line-height: 43px;
      cursor: pointer;
      display: inline-block;
      @include transition(all 0.5s ease-in-out);
      &:hover {
        background-color: $c-01;
        color: $c-03;
      }
      &:before {
        font-family: 'fontAwesome';
        content: '\f077';
      }
    }
  }
}

#social-icon {
  margin-bottom: 5px;
  ul {
    @include no-listing;
    display: inline-block;
    li {
      font-size: 16px;
      display: inline-block;
      a {
        color: $almostwhite;
        padding: 0 5px;
        img{
          max-height: 18px;
          vertical-align: bottom;
        }
      }
    }
  }
}

/*inner page*/
#inner {
  &.no-banner{
    margin-top: 0;
  }
  background-color: white;
  #left-content {
  }
  #newsletter {
    margin: 0 -15px 30px;
    display: block;
    min-height: 100px;
    width: auto;
  }
  .page-content {
    margin-bottom: 30px;
  }
}

#searchBar{
  //display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
  text-align: center;
  padding-top:20px;

  input{
    width: 100%;
    height: 100%;
    font-size: 26px;
    text-align: center;
    color: $color_6;
    border: 0;
    box-shadow: none;
  }
}
.editor-mode [data-block-editable]{
  outline: dashed $color_7 1px;
}

#divChangeViews{
  @media(min-width: 768px){
    display: none;
  }
}

[data-mh] {
  transition: height 0.3s;
}

#enquiry-popup-div {
  .enquiry-popup-block {
    background: white;

    & > form {
      padding: 20px;
    }

    & > h4 {
      margin: 20px;
    }

    input[type=text] {
      height: 34px;
    }

    label {
      font-size: 14px;
      font-weight: normal;
    }

    input[type=text],.input-group-addon, input[type=submit], button {
      border-radius: 0;
    }

    hr {
      margin: 20px 0;
    }

    form {
      margin-bottom: 0;
    }
    #sending {
      color: $c-02;
      @extend %f-a;
      text-transform: uppercase;
    }
  }
}

#facilities {
  margin: 30px 0;
  .header {
    color: $c-02;
    @extend %f-e;
    padding-bottom: 15px;
    margin-bottom: 0;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      background-color: $c-01;
      height: 2px;
      width: 45px;
    }
    @media (max-width: 575px) {
      font-size: 30px !important;
    }
  }
  .title {
    color: $c-02;
    @extend %f-h;
    position: relative;
    padding-bottom: 15px;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      background-color: $c-01;
      height: 2px;
      width: 45px;
    }
  }
  .para {
    color: $c-04;
    @extend %f-g;
  }
  .link {
    a {
      color: $c-03;
      @extend %f-f;
      text-transform: uppercase;
      &:hover {
        color: $c-01;
      }
      position: relative;
      &:after {
        content: '\f054';
        font-family: 'fontAwesome';
        position: absolute;
        top: calc(50% - 7px);
        right: -15px;
      }
    }
  }
  .image {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding-bottom: 15px;
    margin-top: 45px;
    &:after {
      content: '';
      position: absolute;
      top: -13px;
      right: -13px;
      width: 50px;
      height: 50px;
      background-color: $c-03;
      z-index: -1;
    }
  }
}

#faster-safer {
  margin: 90px 0;
  @media (max-width: 991px) {
    margin: 30px 0;
  }
  .block {
    background-color: $c-02;
    text-align: center;
    //padding: 20px 0;
    .paragraph {
      .image-1 {
        padding: 20px 0;
      }
      .title {
        color: $c-05;
        @extend %f-e;
        padding: 0 20px;
        //@media (max-width: 575px) {
        //  font-size: 30px !important;
        //}
      }
      .text-1 {
        color: $c-05;
        @extend %f-a;
        padding: 20px;
        margin-bottom: 20px;
        font-size: 18px;
      }
      position: relative;
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: calc(50% - 22px);
        background-color: $c-01;
        height: 2px;
        width: 45px;
      }
    }
    .text-2 {
      color: $c-05;
      @extend %f-g;
      width: 69%;
      margin: 0 auto;
      margin-bottom: 20px;
      line-height: 30px;
      font-size: 15px;
    }
    .button {
      display: inline-block;
      background-color: $c-03;
      color: $c-05;
      @extend %f-f;
      text-transform: uppercase;
      padding: 18px 32px;
      border-radius: 30px;
      margin-bottom: 20px;
      @include transition(all 0.5s ease);
      &:hover {
        background-color: $c-01;
        color: $c-03;
      }
      cursor: pointer;
    }
  }
  .image-2 {
    position: absolute;
    max-width: 100%;
    height: 120%;
    top: -10%;
    left: -10%;
    z-index: -1;
    @media (max-width: 991px) {
      max-width: 100%;
      width: 100%;
      position: relative;
      top: 0;
      left: 0;
      z-index: 0;
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      height: 100%;
      top: 0;
      left: -15px;
    }
  }
}

#discovery {
  padding-top: 30px;
  .header {
    color: $c-02;
    @extend %f-e;
    padding-bottom: 15px;
    margin-bottom: 0;
    margin-top: -30px;
    &:before {
      content: '';
      position: absolute;
      top: 30px;
      background-color: $c-01;
      height: 2px;
      width: 45px;
    }

    @media (min-width: 576px) and (max-width: 991px) {
      font-size: 30px !important;
      &:before {
        top: 60px;
      }
    }

    @media (min-width: 0px) and (max-width: 575px){
      font-size: 30px !important;
      &:before {
        top: 90px;
      }
    }

  }
  .discovery-arrows {
    .slick-prev {
      background: $c-03;
      position: relative;
      display: inline-block;
      left: 0;
      width: 50px;
      height: 50px;
      &:before {
        font-family: 'fontAwesome';
        content: '\f053';
        font-size: 14px;
        line-height: 1;
        color: $c-05 !important;
        opacity: 1 !important;
      }
      &:hover {
        background: $c-01;
      }
      margin-top: 110px;
    }
    .slick-next {
      background: $c-03;
      position: relative;
      display: inline-block;
      right: 0;
      width: 50px;
      height: 50px;
      &:before {
        font-family: 'fontAwesome';
        content: '\f054';
        font-size: 14px;
        line-height: 1;
        color: $c-05 !important;
        opacity: 1 !important;
      }
      &:hover {
        background: $c-01;
      }
      margin-top: 110px;
    }
  }
  .discovery-wrapper {
    margin-bottom: 0;
    .discovery-slide {
      margin-bottom: 30px;
      box-shadow: 0px 10px 30px -10px $c-04;
      border: 0 !important;
      width: 100%;
      //height: 22vh;
      padding: 30px;
      padding-top: 15px;
      @extend %background_img;
      height: 160px;
      .title-slide {
        @extend %f-e;
        color: $c-05;
        position: relative;
        padding-bottom: 15px;
        margin-top: 60px;
        margin-bottom: 0;
        @media (max-width: 575px) {
          margin-top: 15px;
        }
        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          background-color: $c-05;
          height: 2px;
          width: 45px;
        }
      }
      position: relative;
      .discovery-overlay {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 30px;
        padding-top: 15px;
        background-color: rgba(254, 204, 12, 0.9);
      }
      .title-overlay {
        display: none;
        @extend %f-e;
        color: $c-05;
        position: absolute;
        top: 15px;
        left: 30px;
        opacity: 1;
        &:before {
          content: '';
          position: absolute;
          bottom: -15px;
          background-color: $c-05;
          height: 2px;
          width: 45px;
        }
      }
      .content-overlay {
        display: none;
        @extend %f-g;
        color: $c-05;
        position: absolute;
        top: 90px;
        left: 30px;
        padding-right: 75px;
        opacity: 1;
      }
      .button-overlay {
        display: none;
        color: rgba(254, 204, 12, 0.9);
        background: $c-05;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        font-size: 17px;
        text-align: center;
        line-height: 45px;
        cursor: pointer;
        &:before {
          font-family: 'fontAwesome';
          content: '\f054';
        }
        position: absolute;
        right: 15px;
        bottom: calc(50% - 22px);
        @media (max-width: 575px) {
          right: 5px;
          bottom: 5px;
        }
      }
      &:hover {
        .title-slide {
          display: none;
        }
        .discovery-overlay {
          display: block;
        }
        .title-overlay {
          display: block;
        }
        .content-overlay {
          display: block;
          @media (max-width: 575px) {
            display: none;
          }
        }
        .button-overlay {
          display: inline-block;
        }
      }
    }
  }
  .view-map {
    display: inline-block;
    background-color: $c-03;
    color: $c-05;
    @extend %f-f;
    text-transform: uppercase;
    padding: 18px 32px;
    border-radius: 30px;
    @include transition(all 0.5s ease);
    &:hover {
      background-color: $c-01;
      color: $c-03;
    }
    position: absolute;
    right: 0;
    bottom: 5%;
    @media (max-width: 991px) {
      position: relative;
    }
  }
}

#corporate-information {
  .text-1 {
    color: $c-02;
    @extend %f-e;
    padding-bottom: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      background-color: $c-01;
      height: 2px;
      width: 45px;
    }
  }
  .text-2 {
    color: $c-03;
    @extend %f-j;
    padding-left: 80px;
    position: relative;
    &:before {
      font-family: 'fontAwesome';
      content: '\f10d';
      font-size: 30px;
      position: absolute;
      left: 5px;
      top: 0;
      color: $c-01;
    }
  }
  .text-3 {
    color: $c-02;
    @extend %f-o;
    margin-bottom: 20px;
  }
  p {
    color: $c-04 !important;
    font-family: $proximanova-regular !important;
    font-size: 16px !important;
  }
  .text-4 {
    color: $c-02;
    @extend %f-h;
    padding-bottom: 15px;
    margin-bottom: 15px;
    margin-top: 60px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      background-color: $c-01;
      height: 2px;
      width: 45px;
    }
  }
  .block {
    background-color: $c-05;
    padding: 15px;
    margin: 15px 0;
    &:hover {
      background-color: $c-02;
      .block-right {
        .block-text-1 {
          color: $c-05;
        }
        .block-text-2 {
          a {
            color: $c-01;
          }
        }
      }
    }
    .block-left {
      display: inline-block;
    }
    .block-right {
      display: inline-block;
      padding-left: 15px;
      .block-text-1 {
        color: $c-02;
        @extend %f-o;
        margin-bottom: 8px;
        @media screen and (min-width: 992px) and (max-width: 1199px) {
          font-size: 15px;
        }
      }
      .block-text-2 {
        a {
          color: $c-03;
          @extend %f-f;
          text-transform: uppercase;
          &:hover {
            color: $c-01;
          }
          position: relative;
          &:after {
            content: '\f054';
            font-family: 'fontAwesome';
            position: absolute;
            top: calc(50% - 7px);
            right: -15px;
          }
        }
      }
    }
  }
}

#career {
  .header {
    color: $c-02;
    @extend %f-e;
    padding-bottom: 15px;
    margin-bottom: 15px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      background-color: $c-01;
      height: 2px;
      width: 45px;
    }
    @media screen and (min-width: 0px) and (max-width: 767px) {
      margin-top: 15px;
    }
  }
  .table {
    thead {
      tr {
        td {
          color: $c-04;
          @extend %f-b;
          text-transform: uppercase;
          &:nth-of-type(3) {
            color: $c-02;
            @extend %f-a;
            text-transform: uppercase;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          color: $c-04;
          @extend %f-c;
          padding: 3px 8px;
        }
      }
    }
    margin-bottom: 30px !important;
  }
  .detail {
    position: relative;
    .position {
      color: $c-02;
      @extend %f-o;
      display: inline-block;
      @media screen and (max-width: 575px) {
        margin-bottom: 10px;
      }
    }
    .back-to-top {
      color: $c-02;
      @extend %f-a;
      text-transform: uppercase;
      display: inline-block;
      padding: 0 5px;
      cursor: pointer;
      @media screen and (max-width: 575px) {
        padding: 0;
      }
    }
    .posted {
      color: $c-02;
      @extend %f-a;
      text-transform: uppercase;
    }
    .description {
      color: $c-04 !important;
      font-family: $proximanova-regular !important;
      font-size: 16px !important;
      p {
        color: $c-04 !important;
        font-family: $proximanova-regular !important;
        font-size: 16px !important;
      }
    }
    .enquiry-popup {
      display: inline-block;
      background-color: $c-03;
      color: $c-05;
      @extend %f-f;
      text-transform: uppercase;
      padding: 18px 32px;
      border-radius: 30px;
      @include transition(all 0.5s ease);
      &:hover {
        background-color: $c-01;
        color: $c-03;
      }
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      @media (max-width: 991px) {
        position: relative;
        margin-top: 30px;
      }
    }
  }
  .yellow {
    border-bottom: 2px solid $c-01 !important;
    margin: 30px 0;
  }
}

#announcement-updated {
  margin-top: 30px;
  position: relative;
  .announcement {
    position: static;
    padding-top: 30px;
    padding-left: 30px;
    @media (max-width: 991px) {
      position: relative;
      padding: 30px;
      margin-bottom: 30px;
    }
    &:before {
      content: " ";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 50%;
      background: $c-02;
      background-size: 100%;
      z-index: -1;
      @media (max-width: 991px) {
        width: 100%;
        height: 100%;
      }
    }
    .header {
      color: $c-05;
      @extend %f-e;
      padding-bottom: 15px;
      margin-bottom: 40px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        background-color: $c-01;
        height: 2px;
        width: 45px;
      }
      @media (max-width: 575px) {
        font-size: 30px !important;
      }
    }
    .block {
      .fa {
        font-size: 18px;
        margin-right: 5px;
      }
      .date {
        color: $c-05;
        @extend %f-i;
        padding-bottom: 5px;
      }
      .title {
        color: $c-05;
        @extend %f-b;
        a {
          color: $c-05;
          text-transform: uppercase;
          line-height: 25px;
        }
      }
      padding-right: 110px;
      @media (max-width: 575px) {
        padding-right: 0;
      }
      margin-bottom: 30px;
      position: relative;
      .button {
        color: $c-05;
        background: $c-02;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        font-size: 17px;
        text-align: center;
        line-height: 45px;
        cursor: pointer;
        display: inline-block;
        @include transition(all 0.5s ease-in-out);
        &:hover {
          background-color: $c-01;
          color: $c-02;
        }
        &:before {
          font-family: 'fontAwesome';
          content: '\f054';
        }
        position: absolute;
        top: calc(50% - 22px);
        right: 5%;
        @media (max-width: 575px) {
          position: relative;
        }
      }
    }
    .view-all {
      display: inline-block;
      background-color: $c-03;
      color: $c-05;
      @extend %f-f;
      text-transform: uppercase;
      padding: 18px 32px;
      border-radius: 30px;
      margin-top: 10px;
      @include transition(all 0.5s ease);
      &:hover {
        background-color: $c-01;
        color: $c-03;
      }
    }
  }

  .update {
    position: static;
    padding-top: 30px;
    padding-left: 30px;
    @media (max-width: 991px) {
      position: relative;
      padding: 30px;
      margin-bottom: 30px;
    }
    &:before {
      content: " ";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      right: 0;
      width: 50%;
      background: $c-05;
      background-size: 100%;
      z-index: -1;
      @media (max-width: 991px) {
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .header-switch {
      position: relative;
      .header {
        color: $c-02;
        @extend %f-e;
        padding-bottom: 15px;
        margin-bottom: 40px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          background-color: $c-01;
          height: 2px;
          width: 45px;
        }
        @media (max-width: 575px) {
          font-size: 30px !important;
        }
      }
    }
    .social-media {
      height: 530px;
      .facebook {
        width: 100%;
        height: 500px;
        border: none;
        overflow: hidden;
        @include transition(all 0.5s ease);
      }
      .twitter {
        height: 500px;
        width: auto;
        overflow-x: hidden;
        overflow-y: scroll;
        display: none;
        @include transition(all 0.5s ease);
      }
    }
  }
}

.switch {
  position: absolute;
  display: inline-block;
  width: 60px;
  height: 34px;
  top: 0;
  right: 0;
  cursor: pointer;
  @media (max-width: 575px) {
    top: 100%;
  }
  input {
    display: none;
    &:checked + .slider {
      background-color: #00aced;
    }
    &:focus + .slider {
      box-shadow: 0 0 1px #00aced;
    }
    &:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #3d5a96;
    -webkit-transition: .4s;
    transition: .4s;
    &.round {
      border-radius: 34px;
      &:before {
        border-radius: 50%;
      }
    }
    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: $c-05;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }
  &.on {
    &:before {
      color: $c-05;
    }
    &:after {
      color: #00aced;
    }
    &:hover {
      &:before {
        color: #3d5a96;
      }
    }
  }
  &:before {
    font-family: 'fontAwesome';
    content: "\f09a";
    position: absolute;
    z-index: 9999;
    top: 8px;
    left: 12px;
    font-size: 18px;
    color: #3d5a96;
  }
  &:after {
    font-family: 'fontAwesome';
    content: "\f099";
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 18px;
    color: $c-05;
  }
  &:hover {
    &:after {
      color: #00aced;
    }
  }

}

#mobile-menu {
  .mm-panel {
    background-color: $c-02;
    color: $c-05;
  }
  .mm-title {
    color: $c-05;
  }

  .mm-listview > li .mm-next:after, .mm-menu .mm-listview > li .mm-arrow:after {
    border-color: $c-05;
  }

  .mm-navbar .mm-btn:before, .mm-menu .mm-navbar .mm-btn:after {
    border-color: $c-05;
  }
}

.rc-anchor-aria-status {
  display: block;
  position: absolute;
  bottom: 6px;
  font-family: Roboto,helvetica,arial,sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
  padding-left: 12px;
  color: red;
}

#contactContainer {
  .contact-information-section {
    margin-top: 15px;
    &:nth-child(1) {
      margin-top: 0px;
    }
    .contact-information-section-title {
      color: $c-02;
      @extend %f-o;
    }
    .contact-information-section-description {
      color: $c-04;
      @extend %f-g;
    }
    .contact-information-section-extra {
      color: $c-04;
      @extend %f-g;
    }
  }
}


.boa-block {
  .boa-link {
    color: $c-02;
  }
  .boa-link-2 {
    color: $c-04;
  }
  &:hover {
    .boa-link, .boa-link-2 {
      color: $c-01;
    }
  }
}
