$jsTreeImgPath: '../../img/jstree';
.jstree-default {
  .jstree-icon,
  .jstree-file,
  .jstree-folder, {
    background-image: url("#{$jsTreeImgPath}/32px.png");
  }
}

.jstree-default > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background-image: url("#{$jsTreeImgPath}/throbber.gif");
}

#jstree-dnd.jstree-default .jstree-ok,
#jstree-dnd.jstree-default .jstree-er,
#jstree-dnd.jstree-default-small .jstree-ok,
#jstree-dnd.jstree-default-small .jstree-er,
.jstree-default-large .jstree-node,
.jstree-default-large .jstree-icon,
#jstree-dnd.jstree-default-large .jstree-ok,
#jstree-dnd.jstree-default-large .jstree-er{
  background-image: url("#{$jsTreeImgPath}/32px.png");
}

.jstree-default-small {
  .jstree-icon,
  .jstree-file,
  .jstree-folder, {
    background-image: url("#{$jsTreeImgPath}/32px.png");
  }
}

.jstree-default-large {
  .jstree-icon,
  .jstree-file,
  .jstree-folder, {
    background-image: url("#{$jsTreeImgPath}/32px.png");
  }
}

#jstree-dnd.jstree-dnd-responsive {
  >.jstree-ok,
  >.jstree-er {
    background-image: url("#{$jsTreeImgPath}/40px.png");
  }
}

.jstree-default-responsive .jstree-node,
.jstree-default-responsive .jstree-icon,
.jstree-default-responsive .jstree-node > .jstree-ocl,
.jstree-default-responsive .jstree-themeicon,
.jstree-default-responsive .jstree-checkbox,
.jstree-default-responsive .jstree-file,
.jstree-default-responsive .jstree-folder {
  background-image: url("#{$jsTreeImgPath}/40px.png");
}