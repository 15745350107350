$slickFontsFolder: '../../fonts/slick';
$slickImgFolder: '../../img/slick';

.slick-loading .slick-list {
  background-image: url('#{$slickImgFolder}/ajax-loader.gif');
}

@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url('#{$slickFontsFolder}/slick.eot');
  src: url('#{$slickFontsFolder}/slick.eot?#iefix') format('embedded-opentype'), url('#{$slickFontsFolder}/slick.woff') format('woff'), url('#{$slickFontsFolder}/slick.ttf') format('truetype'), url('#{$slickFontsFolder}/slick.svg#slick') format('svg');
}

.slick-arrow:before{
  transition: opacity 0.5s;
}