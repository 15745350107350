/* Bourbon */
.top-header .user-pref#currency .currency-cont li a:hover {
  color: #1ca7e0;
}

.top-header .user-pref#search:hover {
  background-color: #1ca7e0;
  color: #ffffff;
}

@font-face {
  font-family: "ProximaNova-Regular";
  src: url("../../fonts/webfonts/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/webfonts/ProximaNova-Regular.otf") format("otf"), url("../../fonts/webfonts/ProximaNova-Regular.woff") format("woff"), url("../../fonts/webfonts/ProximaNova-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: url("../../fonts/webfonts/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/webfonts/ProximaNova-Bold.otf") format("otf"), url("../../fonts/webfonts/ProximaNova-Bold.woff") format("woff"), url("../../fonts/webfonts/ProximaNova-Bold.ttf") format("truetype");
}

/*@include font-face('helvetica_ce_35_thinregular', "../../fonts/webfonts/helvetica-webfont");
@include font-face('montserratregular', "../../fonts/webfonts/montserrat-bold-webfont");
@include font-face('montserratbold', "../../fonts/webfonts/montserrat-regular-webfont");*/
/* Bourbon */
.clearfix,
.group {
  zoom: 1;
}

.clearfix:before, .clearfix:after,
.group:before,
.group:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after,
.group:after {
  clear: both;
}

.buffer-xs-0 {
  margin-top: 0;
}

.buffer-xs-10 {
  margin-top: 10px;
}

.buffer-xs-20 {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .buffer-sm-0 {
    margin-top: 0;
  }
  .buffer-sm-10 {
    margin-top: 10px;
  }
}

@media (min-width: 992px) {
  .buffer-md-0 {
    margin-top: 0;
  }
  .buffer-md-10 {
    margin-top: 10px;
  }
}

.icheckbox_square-blue,
.iradio_square-blue {
  background-image: url("../../img/iCheck/square/blue.png");
  /* HiDPI support */
}

@media (-o-min-device-pixel-ratio: 5 / 4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  .icheckbox_square-blue,
  .iradio_square-blue {
    background-image: url("../../img/iCheck/square/blue@2x.png");
  }
}

.bannerMfp .mfp-iframe {
  background: none;
  box-shadow: none;
}

.mfp-bg {
  z-index: 999998;
}

.mfp-wrap {
  z-index: 999999;
}

.uno-mfp-popup {
  max-width: 600px;
  margin: 10px auto;
  color: #000;
  font-family: helvetica, arial, sans-serif;
  position: relative;
}

.uno-mfp-popup.white {
  background-color: white;
}

.uno-mfp-popup.white .mfp-close {
  color: black;
  font-size: 22px;
}

.uno-mfp-popup.white > .box {
  border-radius: 0;
}

.uno-mfp-popup > .popup-container {
  padding: 20px;
}

.uno-mfp-popup > .popup-container > h4 {
  margin: 0;
}

.mfp-iframe-holder .mfp-content {
  max-width: 1200px;
}

/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.mfp-close-btn-in .mfp-close {
  color: #FFF;
}

.select2-dropdown, .select2-container--bootstrap .select2-search--dropdown .select2-search__field, .select2-container--bootstrap .select2-selection {
  border-radius: 0;
}

.slick-loading .slick-list {
  background-image: url("../../img/slick/ajax-loader.gif");
}

@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../../fonts/slick/slick.eot");
  src: url("../../fonts/slick/slick.eot?#iefix") format("embedded-opentype"), url("../../fonts/slick/slick.woff") format("woff"), url("../../fonts/slick/slick.ttf") format("truetype"), url("../../fonts/slick/slick.svg#slick") format("svg");
}

.slick-arrow:before {
  transition: opacity 0.5s;
}

/* TinyMCE Fullscreen Z-Index Override */
div.mce-fullscreen {
  z-index: 9999;
}

label.mce-label {
  max-width: none;
}

/* TINYMCE CUSTOMISATION */
.mce-window {
  max-width: 90% !important;
}

.mce-panel {
  max-width: 100% !important;
}

.mce-tabs {
  max-width: 100% !important;
}

.mce-container-body {
  max-width: 100% !important;
}

.mce-container {
  max-width: 100% !important;
}

.mejs-overlay-button {
  background-image: url("../../assets/mediaelement/bigplay.svg");
}

.no-svg .mejs-overlay-button {
  background-image: url("../../assets/mediaelement/bigplay.png");
}

.mejs-overlay-loading {
  background-image: url("../../assets/mediaelement/background.png");
}

.mejs-overlay-loading span {
  background-image: url("../../assets/mediaelement/loading.gif");
}

.mejs-container .mejs-controls,
.mejs-controls .mejs-volume-button .mejs-volume-slider,
.mejs-controls .mejs-captions-button .mejs-captions-selector,
.mejs-captions-text,
.mejs-controls .mejs-sourcechooser-button .mejs-sourcechooser-selector,
.mejs-postroll-layer,
.mejs-postroll-close,
.mejs-controls .mejs-speed-button .mejs-speed-selector {
  background-image: url("../../assets/mediaelement/background.png");
}

.mejs-controls .mejs-button button {
  background-image: url("../../assets/mediaelement/controls.svg");
}

.no-svg .mejs-controls .mejs-button button {
  background-image: url("../../assets/mediaelement/controls.png");
}

.mejs-controls .mejs-button.mejs-skip-back-button {
  background-image: url("../../assets/mediaelement/skipback.png");
}

.jstree-default .jstree-icon,
.jstree-default .jstree-file,
.jstree-default .jstree-folder {
  background-image: url("../../img/jstree/32px.png");
}

.jstree-default > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background-image: url("../../img/jstree/throbber.gif");
}

#jstree-dnd.jstree-default .jstree-ok,
#jstree-dnd.jstree-default .jstree-er,
#jstree-dnd.jstree-default-small .jstree-ok,
#jstree-dnd.jstree-default-small .jstree-er,
.jstree-default-large .jstree-node,
.jstree-default-large .jstree-icon,
#jstree-dnd.jstree-default-large .jstree-ok,
#jstree-dnd.jstree-default-large .jstree-er {
  background-image: url("../../img/jstree/32px.png");
}

.jstree-default-small .jstree-icon,
.jstree-default-small .jstree-file,
.jstree-default-small .jstree-folder {
  background-image: url("../../img/jstree/32px.png");
}

.jstree-default-large .jstree-icon,
.jstree-default-large .jstree-file,
.jstree-default-large .jstree-folder {
  background-image: url("../../img/jstree/32px.png");
}

#jstree-dnd.jstree-dnd-responsive > .jstree-ok,
#jstree-dnd.jstree-dnd-responsive > .jstree-er {
  background-image: url("../../img/jstree/40px.png");
}

.jstree-default-responsive .jstree-node,
.jstree-default-responsive .jstree-icon,
.jstree-default-responsive .jstree-node > .jstree-ocl,
.jstree-default-responsive .jstree-themeicon,
.jstree-default-responsive .jstree-checkbox,
.jstree-default-responsive .jstree-file,
.jstree-default-responsive .jstree-folder {
  background-image: url("../../img/jstree/40px.png");
}

/* No bold */
.no-bold {
  font-weight: normal;
}

/* IE Image Fix */
img {
  -ms-interpolation-mode: bicubic;
}

/* Easy background image */
.bg-img {
  background: no-repeat center;
}

.bg-img.bg-cover {
  background-size: cover;
}

.bg-img.bg-contain {
  background-size: contain;
}

/* Button Block Text Overflow */
.btn-block {
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Hide CSRF Token */
#_token {
  display: none;
}

/* Bootstrap: Remove gutter */
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

/* Remove Transition */
.no-transition {
  transition: none !important;
}

a.block {
  display: block;
}

.btn:focus {
  outline: none;
}

body {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
}

#news-mini .news .slide .date {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

body {
  line-height: 1.2;
}

blockquote {
  border-left: 2px solid #1271db;
  font-family: Georgia, Times, "Times New Roman", serif;
  font-size: 1.4em;
  font-style: italic;
  margin: 0 0 20px;
  padding-left: 25px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Open Sans", sans-serif;
  line-height: 1;
  font-weight: bold;
}

h1 {
  font-size: 42px;
  font-style: normal;
  margin: 0 0 25px;
}

h2 {
  font-size: 30px;
  font-style: normal;
  margin: 0 0 25px;
}

h3 {
  font-size: 24px;
  font-style: normal;
  margin: 0 0 25px;
}

h4 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin: 0 0 25px;
}

h5 {
  font-size: 16px;
  font-style: normal;
  margin: 0 0 25px;
}

p {
  margin: 0 0 20px;
  font-size: 12px;
}

pre {
  background: #222;
  color: #fff;
  display: block;
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 13px;
  line-height: 20px;
  margin: 0 0 20px;
  padding: 10px;
  word-break: break-all;
  word-wrap: break-word;
}

code {
  border-radius: 5px;
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 13px;
  line-height: 20px;
  padding: 2px 10px;
  word-break: break-all;
  word-wrap: break-word;
}

a {
  color: #1271db;
  display: inline-block;
}

a:hover, a:focus {
  text-decoration: none;
}

/*

// Responsive styles if needed
// @include responsive($big-break) {
// }
@include responsive($small-break) {
  body {
    font-size: 14px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 10px;
  }

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h4,
  h5,
  p,
  ul,
  ol {
    font-size: 14px;
  }
}
*/
.upper-header ul li div a, #breadcrumb, footer #footer-latar li a, #enquiry-popup-div .enquiry-popup-block #sending, #faster-safer .block .paragraph .text-1, #career .table thead tr td:nth-of-type(3), #career .detail .back-to-top, #career .detail .posted {
  font-family: "ProximaNova-Regular";
  font-size: 12px;
}

#menu nav ul li a, #career .table thead tr td, #announcement-updated .announcement .block .title {
  font-family: "ProximaNova-Bold";
  font-size: 16px;
}

#menu nav ul li ul li a, #career .table tbody tr td {
  font-family: "ProximaNova-Regular";
  font-size: 16px;
}

#banner .descs .desc .title {
  font-family: "ProximaNova-Regular";
  font-size: 38px;
}

#banner .descs .desc p, #inner-banner .inner-banner-title, #subscribe .text-1, #facilities .header, #faster-safer .block .paragraph .title, #discovery .header, #discovery .discovery-wrapper .discovery-slide .title-slide, #discovery .discovery-wrapper .discovery-slide .title-overlay, #corporate-information .text-1, #career .header, #announcement-updated .announcement .header, #announcement-updated .update .header-switch .header {
  font-family: "ProximaNova-Bold";
  font-size: 38px;
}

.btn, #banner .descs .desc .link, #subscribe #newsletter, #facilities .link a, #faster-safer .block .button, #discovery .view-map, #corporate-information .block .block-right .block-text-2 a, #career .detail .enquiry-popup, #announcement-updated .announcement .view-all {
  font-family: "ProximaNova-Bold";
  font-size: 13px;
}

#content .page-content p, #facilities .para, #faster-safer .block .text-2, #discovery .discovery-wrapper .discovery-slide .content-overlay, #contactContainer .contact-information-section .contact-information-section-description, #contactContainer .contact-information-section .contact-information-section-extra {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
}

#content .page-content h1, #content .page-content h2, #content .page-content h3, footer #footer-latar .title, #facilities .title, #corporate-information .text-4 {
  font-family: "ProximaNova-Bold";
  font-size: 28px;
}

#announcement-updated .announcement .block .date {
  font-family: "ProximaNova-Regular";
  font-size: 18px;
}

#subscribe .text-2, #corporate-information .text-2 {
  font-family: "ProximaNova-Regular";
  font-size: 28px;
}

footer #footer-latar .lower-footer .sitemap {
  font-family: "ProximaNova-Regular";
  font-size: 11px;
}

#search-bar {
  font-family: "ProximaNova-Regular";
  font-size: 32px;
}

#corporate-information .text-3, #corporate-information .block .block-right .block-text-1, #career .detail .position, #contactContainer .contact-information-section .contact-information-section-title {
  font-family: "ProximaNova-Bold";
  font-size: 22px;
}

html, body, #wrapper {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

#wrapper {
  display: table;
  width: 100%;
  table-layout: fixed;
}

main {
  display: table-row;
}

header {
  display: table-row;
  height: 1px;
}

footer {
  display: table-row;
  height: 1px;
}

.mm-page {
  height: 100%;
}

/* columns of same height styles */
.row-full-height {
  height: 100%;
}

.col-full-height {
  height: 100%;
  vertical-align: middle;
}

.row-same-height, .row-md-same-height, .row-sm-same-height {
  display: table;
  table-layout: fixed;
}

/*revert back to normal in md*/
.row-md-same-height {
  display: table;
  table-layout: fixed;
}

@media (max-width: 992px) {
  .row-md-same-height {
    display: block;
  }
}

.row-sm-same-height {
  display: table;
  table-layout: fixed;
}

@media (max-width: 768px) {
  .row-sm-same-height {
    display: block;
  }
}

.col-xs-height {
  display: table-cell;
  float: none !important;
}

/*alignments*/
.col-top {
  vertical-align: top;
}

.col-middle {
  vertical-align: middle;
}

.col-bottom {
  vertical-align: bottom;
}

@media (min-width: 768px) {
  .col-sm-height {
    display: table-cell;
    float: none !important;
  }
}

@media (min-width: 992px) {
  .col-md-height {
    display: table-cell;
    float: none !important;
  }
}

@media (min-width: 1000px) {
  .col-lg-height {
    display: table-cell;
    float: none !important;
  }
}

#discovery .discovery-wrapper .discovery-slide {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

/* columns of same height styles */
.row-full-height {
  height: 100%;
}

.col-full-height {
  height: 100%;
  vertical-align: middle;
}

.row-same-height, .row-md-same-height, .row-sm-same-height {
  display: table;
  table-layout: fixed;
  width: 100%;
  vertical-align: middle;
}

/*revert back to normal in md*/
@media (max-width: 991px) {
  .row-md-same-height {
    display: block;
  }
}

@media (max-width: 767px) {
  .row-sm-same-height {
    display: block;
  }
}

.col-xs-height {
  display: table-cell;
  float: none !important;
}

/*alignments*/
.col-top {
  vertical-align: top;
}

.col-middle {
  vertical-align: middle;
}

.col-bottom {
  vertical-align: bottom;
}

@media (min-width: 767px) {
  .col-sm-height {
    display: table-cell;
    float: none !important;
  }
}

@media (min-width: 991px) {
  .col-md-height {
    display: table-cell;
    float: none !important;
  }
}

@media (min-width: 1000px) {
  .col-lg-height {
    display: table-cell;
    float: none !important;
  }
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters [class^="col-"], .row.no-gutters [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

.row-centered {
  text-align: center;
}

.row-centered .col-centered {
  display: inline-block;
  float: none;
  vertical-align: top;
  text-align: left;
  margin-left: -4px;
}

.v-align-bottom {
  display: table-cell;
  float: none !important;
  vertical-align: bottom;
}

.col-xs-1-5, .col-sm-1-5, .col-md-1-5, .col-lg-1-5, .col-xs-2-5, .col-sm-2-5, .col-md-2-5, .col-lg-2-5, .col-xs-3-5, .col-sm-3-5, .col-md-3-5, .col-lg-3-5, .col-xs-4-5, .col-sm-4-5, .col-md-4-5, .col-lg-4-5 {
  position: relative;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
}

@media (max-width: 991px) {
  .col-xs-1-5, .col-sm-1-5, .col-md-1-5, .col-lg-1-5, .col-xs-2-5, .col-sm-2-5, .col-md-2-5, .col-lg-2-5, .col-xs-3-5, .col-sm-3-5, .col-md-3-5, .col-lg-3-5, .col-xs-4-5, .col-sm-4-5, .col-md-4-5, .col-lg-4-5 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.col-xs-1-5 {
  width: 20%;
  float: left;
}

.col-xs-2-5 {
  width: 40%;
  float: left;
}

.col-xs-3-5 {
  width: 60%;
  float: left;
}

.col-xs-4-5 {
  width: 80%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-1-5 {
    width: 20%;
    float: left;
  }
  .col-sm-2-5 {
    width: 40%;
    float: left;
  }
  .col-sm-3-5 {
    width: 60%;
    float: left;
  }
  .col-sm-4-5 {
    width: 80%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-1-5 {
    width: 20%;
    float: left;
  }
  .col-md-2-5 {
    width: 40%;
    float: left;
  }
  .col-md-3-5 {
    width: 60%;
    float: left;
  }
  .col-md-4-5 {
    width: 80%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-1-5 {
    width: 20%;
    float: left;
  }
  .col-lg-2-5 {
    width: 40%;
    float: left;
  }
  .col-lg-3-5 {
    width: 60%;
    float: left;
  }
  .col-lg-4-5 {
    width: 80%;
    float: left;
  }
}

#discovery .discovery-wrapper .discovery-slide {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.m-left {
  text-align: left;
}

.m-center {
  text-align: center;
}

.m-right {
  text-align: right;
}

@media (max-width: 9999px) {
  .m-lg-left {
    text-align: left;
  }
  .m-lg-center {
    text-align: center;
  }
  .m-lg-right {
    text-align: right;
  }
}

@media (max-width: 1199px) {
  .m-md-left {
    text-align: left;
  }
  .m-md-center {
    text-align: center;
  }
  .m-md-right {
    text-align: right;
  }
}

@media (max-width: 991px) {
  .m-sm-left {
    text-align: left;
  }
  .m-sm-center {
    text-align: center;
  }
  .m-sm-right {
    text-align: right;
  }
}

@media (max-width: 767px) {
  .m-xs-left {
    text-align: left;
  }
  .m-xs-center {
    text-align: center;
  }
  .m-xs-right {
    text-align: right;
  }
}

@media (max-width: 575px) {
  .m-xx-left {
    text-align: left;
  }
  .m-xx-center {
    text-align: center;
  }
  .m-xx-right {
    text-align: right;
  }
}

img,
video,
audio,
iframe,
object {
  max-width: 100%;
}

.btn {
  border-radius: 25px;
  background: #0066b3;
  border: 1px solid #0066b3;
  color: #f5f5f5;
  display: inline-block;
  height: 44px;
  outline: none;
  padding: 0 25px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.btn:hover, .btn:focus {
  background: #fecc0c;
  border-color: #fecc0c;
  color: #0066b3;
  text-decoration: none;
}

.btn:active {
  background: #0e59ac;
  border-color: #0e59ac;
}

.btn-primary {
  background-color: #1271db;
  border-color: #1271db;
  color: #fff;
}

.btn-primary:hover, .btn-primary:focus {
  background: #1065c3;
  border-color: #1065c3;
  color: #fff;
}

.btn-primary:active {
  background: #0e59ac;
  border-color: #0e59ac;
  color: #fff;
}

.btn-lg {
  font-size: 1.4em;
  height: 66px;
  padding: 0 60px;
}

.btn-sm {
  font-size: .85em;
  height: 30.8px;
  padding: 0 10px;
}

.btn-link {
  box-shadow: none;
  background: transparent;
  border: 0;
  color: #1271db;
  text-shadow: none;
  text-transform: none;
}

.btn-link:hover, .btn-link:focus {
  background: transparent;
  color: #1271db;
  text-decoration: underline;
}

@media screen and (max-width: 480px) {
  .btn {
    display: block;
    margin-bottom: 10px;
    width: 100%;
  }
}

table {
  width: 100%;
}

table th,
table td {
  padding: 5px;
  text-align: left;
}

table th {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type=text],
input[type=email],
input[type=phone],
input[type=password],
input[type=number],
input[type=search],
textarea {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: 1px solid #ccc;
  height: 44px;
  outline: none;
  padding: 5px 10px;
  width: 300px;
}

input[type=text]:focus,
input[type=email]:focus,
input[type=phone]:focus,
input[type=password]:focus,
input[type=number]:focus,
input[type=search]:focus,
textarea:focus {
  border-color: #1271db;
}

input[type=text].full-width,
input[type=email].full-width,
input[type=phone].full-width,
input[type=password].full-width,
input[type=number].full-width,
input[type=search].full-width,
textarea.full-width {
  width: 100%;
}

textarea {
  height: 132px;
}

form {
  margin-bottom: 40px;
}

form .field {
  margin-bottom: 20px;
}

form .hint {
  color: #aaa;
  margin-top: 5px;
}

form .is-required {
  font-style: italic;
}

form .has-error .error-description {
  color: #ff4136;
  margin-top: 5px;
}

form .has-error input[type=text],
form .has-error input[type=email],
form .has-error input[type=phone],
form .has-error input[type=password],
form .has-error input[type=number],
form .has-error input[type=search],
form .has-error textarea {
  border-color: #ff4136;
}

.alert {
  zoom: 1;
  border-left: 3px solid;
  margin-bottom: 40px;
  padding: 10px;
}

.alert:before, .alert:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
}

.alert:after {
  clear: both;
}

.alert.alert-danger {
  background: #ffeae9;
  border-color: #ff4136;
  color: #e90d00;
}

.alert.alert-success {
  background: #e6f9e8;
  border-color: #2ecc40;
  color: #25a233;
}

.alert.alert-warning {
  background: #fff2e7;
  border-color: #ff851b;
  color: #e76b00;
}

.alert.alert-info {
  background: #cfe9ff;
  border-color: #0074d9;
  color: #0059a6;
}

.alert.alert-empty {
  background: #f4f4f4;
  border: 1px dashed #ddd;
  text-align: center;
}

.alert p:last-child {
  margin-bottom: 0;
}

.alert .close {
  right: 0;
}

.backend-error-icon, .backend-error-text {
  font-weight: bold;
}

.backend-error-icon.danger, .backend-error-text.danger {
  color: red;
}

.backend-error-icon.warning, .backend-error-text.warning {
  color: orange;
}

.backend-error-icon.success, .backend-error-text.success {
  color: green;
}

.backend-error-icon {
  margin-right: 5px;
}

.top-header .user-pref#search:hover {
  background-color: #1ca7e0;
  color: #ffffff;
}

#menu {
  position: relative;
  padding: 0;
  padding-right: 65px;
}

#menu nav {
  float: right;
}

#menu nav ul {
  list-style: none;
  position: relative;
  float: left;
  padding: 0;
  margin-top: 80px;
  margin-bottom: 30px;
  margin-right: 0;
}

#menu nav ul li {
  position: relative;
  float: left;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

#menu nav ul li a {
  color: #000000;
  display: block;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 5px;
  text-transform: uppercase;
}

#menu nav ul li a:hover {
  color: #0066b3;
}

#menu nav ul li:hover > ul {
  display: block;
}

#menu nav ul li ul {
  color: white;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  padding: 0;
  z-index: 99999;
  padding-top: 10px;
  margin: 0;
}

#menu nav ul li ul li {
  float: none;
  width: 280px;
}

#menu nav ul li ul li a {
  border-radius: 0;
  background-color: rgba(0, 102, 179, 0.9);
  color: #f5f5f5;
  padding: 15px 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  text-transform: none;
}

#menu nav ul li ul li:hover > a {
  background-color: #224897;
  color: #f5f5f5;
}

#menu nav ul li ul li ul {
  top: 0;
  left: 100%;
  padding: 0;
  padding-left: 15px;
}

#menu nav ul li ul li ul li a {
  padding: 15px 20px;
}

#menu nav.mobile {
  display: none;
}

#random-logo {
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 1;
  width: 20%;
}

@media (max-width: 991px) {
  #random-logo {
    left: 30px;
    top: 10px;
    text-align: left;
    width: 110px;
  }
}

#search-btn {
  position: absolute;
  top: 70px;
  right: 0;
  background: #0066b3;
  color: #f5f5f5;
  padding: 18px 20px;
  border-radius: 100%;
  display: inline-block;
  cursor: pointer;
  z-index: 1;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#search-btn .fa {
  width: 10px !important;
}

#search-btn:hover {
  background: #fecc0c;
  color: #0066b3;
}

#search-bar {
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: none !important;
  border-radius: 0 !important;
  border: none !important;
  color: #224897;
  padding-top: 45px;
  background-color: #f5f5f5;
}

#search-bar::-webkit-input-placeholder {
  color: #224897 !important;
  font-family: "ProximaNova-Regular", "fontAwesome";
  font-size: 24px !important;
}

#search-bar::-moz-placeholder {
  color: #224897 !important;
  font-family: "ProximaNova-Regular", "fontAwesome";
  font-size: 24px !important;
}

#search-bar:-moz-placeholder {
  color: #224897 !important;
  font-family: "ProximaNova-Regular", "fontAwesome";
  font-size: 24px !important;
}

#search-bar:-ms-input-placeholder {
  color: #224897 !important;
  font-family: "ProximaNova-Regular", "fontAwesome";
  font-size: 24px !important;
}

#search-bar-mobile {
  box-shadow: none !important;
  border-radius: 0 !important;
  border: none !important;
  margin: 10px 10px 10px 20px;
  width: 100%;
  color: #224897;
}

* {
  box-sizing: border-box !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.clear {
  clear: both;
}

.right {
  float: right;
}

.left {
  float: left;
}

.list-unstyled {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.valign {
  vertical-align: middle;
}

hr {
  border: 0;
  border-bottom: 1px solid #ddd;
  margin: 20px 0;
}

.subtle {
  color: #aaa;
}

.relative {
  position: relative;
}

.relative {
  position: relative;
}

.none {
  display: none;
}

.no-padding {
  padding: 0;
}

.left-0-padding {
  padding-left: 0;
}

.right-0-padding {
  padding-right: 0;
}

.top-0-padding {
  padding-top: 0;
}

.bottom-0-padding {
  padding-bottom: 0;
}

.no-gutters {
  padding-left: 0;
  padding-right: 0;
}

.dark-blue {
  color: #004161;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.title-lg-inline {
  display: inline-block;
}

@media (max-width: 991px) {
  .title-lg-inline {
    display: block;
  }
}

.title-md-inline {
  display: inline-block;
}

@media (max-width: 768px) {
  .title-md-inline {
    display: block;
  }
}

.center-of-unknown:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.center-of-unknown > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
}

#header-content {
  position: relative;
  border-top: 5px solid #fecc0c;
}

.upper-header {
  background-color: #224897;
  width: 100%;
  color: #f5f5f5;
}

.upper-header ul {
  display: inline-block;
  margin-bottom: 0;
  list-style: none;
  padding: 20px 0;
}

.upper-header ul li {
  display: inline;
  padding: 0 10px;
  position: relative;
}

.upper-header ul li div {
  display: inline;
}

.upper-header ul li div a {
  color: #f5f5f5;
}

.upper-header ul li:after {
  content: '';
  background-color: #f5f5f5;
  position: absolute;
  top: 25%;
  right: -1%;
  width: 1px;
  height: 50%;
}

.upper-header ul li:last-child:after {
  content: '';
  width: 0;
}

@media (max-width: 991px) {
  .upper-header ul li {
    padding: 0;
    display: block;
  }
  .upper-header ul li:after {
    content: '';
    width: 0;
  }
}

.lower-header {
  background-color: #f5f5f5;
  width: 100%;
  color: #224897;
  position: relative;
}

.lower-header .logo-img {
  position: absolute;
  top: -30px;
  z-index: 9999;
}

.top-header {
  background-color: #2C2C2C;
  padding: 0;
  color: #ffffff;
  /*end of USER-PREF*/
}

.top-header .user-pref {
  float: right;
  /*end of currency*/
  /*end of search*/
}

.top-header .user-pref#currency .currency-cont {
  margin: 0;
  padding: 0;
  /*end of li*/
}

.top-header .user-pref#currency .currency-cont li {
  display: inline-block;
  border-left: 1px solid #151515;
  box-shadow: rgba(255, 255, 255, 0.2) -1px 0 0 0;
  padding: 10px;
  /*end of a*/
}

.top-header .user-pref#currency .currency-cont li * {
  display: inline-block;
  vertical-align: middle;
}

.top-header .user-pref#currency .currency-cont li a {
  text-transform: uppercase;
  color: white;
  font-size: 10px;
  text-decoration: none;
  line-height: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.top-header .user-pref#currency .currency-cont li .flag {
  vertical-align: middle;
}

.top-header .user-pref#search {
  padding: 0 15px;
  line-height: 36px;
  cursor: pointer;
  border-left: 1px solid #151515;
  border-right: 1px solid #151515;
  box-shadow: rgba(255, 255, 255, 0.2) -1px 0 0 0, rgba(255, 255, 255, 0.2) 1px 0 0 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.top-header .user-pref#search.active {
  background-color: #369FDC;
}

.bottom-header #logo {
  margin: 30px 0;
  padding: 0;
}

#menuBtn {
  font-size: 40px;
  text-align: right;
  margin: 45px 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#menuBtn a {
  color: #224897 !important;
}

#menuBtn a:hover {
  color: #fecc0c !important;
}

#banner {
  position: relative;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  /*end of slides*/
  /*end of descs*/
}

#banner .slides {
  margin-bottom: 0 !important;
}

#banner .slides .slide {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 490px;
}

@media (max-width: 768px) {
  #banner .slides .slide {
    height: 320px;
  }
}

#banner .slides .slide[href="#"] {
  cursor: default;
}

#banner .slides-dots-absolute {
  position: absolute;
  bottom: 0;
  width: 100%;
}

#banner .slides-dots-absolute .slides-dots .slick-dots {
  position: relative !important;
  bottom: 0 !important;
  text-align: right !important;
}

#banner .slides-dots-absolute .slides-dots .slick-dots li button:before {
  font-size: 16px !important;
  color: #f5f5f5;
  opacity: 1;
}

#banner .slides-dots-absolute .slides-dots .slick-dots li.slick-active button:before {
  font-size: 16px !important;
  color: #fecc0c;
  opacity: 1;
}

#banner .descs {
  width: 100%;
  max-width: 620px;
  position: absolute;
  padding: 30px;
  bottom: 20px;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}

@media (max-width: 991px) {
  #banner .descs {
    width: 90%;
  }
}

@media (max-width: 768px) {
  #banner .descs {
    max-width: none;
  }
}

#banner .descs .desc .title {
  text-transform: uppercase;
  color: #0066b3;
  line-height: 50px;
  margin-bottom: 5px;
  background-color: #fecc0c;
  display: inline;
}

@media (max-width: 991px) {
  #banner .descs .desc .title {
    font-size: 20px !important;
    line-height: 25px !important;
  }
}

#banner .descs .desc p {
  text-transform: uppercase;
  color: #0066b3;
  background-color: #fecc0c;
  line-height: 50px;
  display: inline;
}

@media (max-width: 991px) {
  #banner .descs .desc p {
    font-size: 20px !important;
    line-height: 25px !important;
  }
}

#banner .descs .desc .link {
  display: inline-block;
  background-color: #0066b3;
  color: #f5f5f5;
  text-transform: uppercase;
  padding: 18px 32px;
  border-radius: 30px;
  margin-top: 30px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#banner .descs .desc .link:hover {
  background-color: #fecc0c;
  color: #0066b3;
}

#banner .descs.slick-slider .slick-dots {
  bottom: 0;
  left: 0;
}

#banner .descs.slick-slider .slick-dots li button {
  width: 10px;
  height: 10px;
}

#banner .descs.slick-slider .slick-dots li button:before {
  font-size: 10px;
  color: #1ca7e0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  opacity: 0.5;
  width: auto;
  height: auto;
}

#banner .descs.slick-slider .slick-dots li.slick-active button:before, #banner .descs.slick-slider .slick-dots li button:hover:before {
  color: #004161;
  opacity: 1;
  font-size: 12px;
}

#banner .descs.slick-slider .slick-prev:before, #banner .descs.slick-slider .slick-next:before {
  color: #004161;
}

#banner .descs.slick-slider .slick-slide:focus {
  outline: none;
}

#inner-banner {
  margin-bottom: 0 !important;
}

#inner-banner .slide {
  width: 100%;
  height: 240px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#inner-banner .inner-banner-title {
  text-transform: uppercase;
  color: #0066b3;
  background-color: #fecc0c;
  line-height: 30px;
  padding: 5px;
  position: absolute;
  top: 65%;
  display: inline;
}

@media (max-width: 991px) {
  #inner-banner .inner-banner-title {
    font-size: 20px !important;
    line-height: 25px !important;
  }
}

#breadcrumb {
  display: none;
  padding: 30px 0px;
}

@media (min-width: 768px) {
  #breadcrumb {
    display: block;
  }
}

#breadcrumb .text {
  color: #224897;
}

#breadcrumb .crumb {
  color: #224897;
}

#breadcrumb .crumb:after {
  content: '\25B6';
  margin: 0 5px;
  font-size: 10px;
}

#breadcrumb .crumb:last-child:after {
  content: '';
}

#content .page-content h1, #content .page-content h2, #content .page-content h3 {
  color: #224897;
  padding: 15px 0;
  margin: 15px 0 0;
}

#content .page-content p {
  color: #000000;
  text-align: justify;
}

#content .page-content img {
  max-width: 100%;
  display: block;
}

#content .page-content .read-more {
  color: #004161;
  font-size: 13px;
  position: relative;
}

#content .page-content .read-more:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-color: #1ca7e0;
  top: 50%;
  left: 100%;
  margin-left: 5px;
  margin-top: -3px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid #1ca7e0;
  border-left-color: inherit;
}

#content .page-content .left-content {
  background-color: #ffffff;
}

#content .page-content .left-content > *:last-child {
  margin-bottom: 30px;
}

#content .page-content .right-content {
  background-color: #F3F3F3;
}

#content .page-content .right-content > *:last-child {
  margin-bottom: 30px;
}

#content .bottom-content {
  margin-top: 30px;
}

#news-mini {
  border-top: 15px solid rgba(255, 255, 255, 0.1);
  border-bottom: 15px solid rgba(0, 0, 0, 0.06);
  padding: 20px;
  background-color: #1ca7e0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  /*end of news*/
}

#news-mini:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

#news-mini > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
}

#news-mini .title-cont {
  position: relative;
  border-right: 1px solid #0083B4;
  box-shadow: #16BCF9 1px 0 0 0;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  #news-mini .title-cont {
    width: 100%;
    border: none;
    box-shadow: none;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #0083B4;
    box-shadow: #16BCF9 0 1px 0 0;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
}

#news-mini .title-cont .title {
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  #news-mini .title-cont .title {
    display: inline-block;
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  #news-mini .title-cont .title {
    display: inline-block;
  }
}

#news-mini .title-cont .link {
  color: #004161;
  display: inline-block;
  font-size: 13px;
  position: relative;
}

#news-mini .title-cont .link:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-color: white;
  top: 50%;
  left: 100%;
  margin-left: 5px;
  margin-top: -3px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid white;
  border-left-color: inherit;
}

@media (max-width: 768px) {
  #news-mini .title-cont .link {
    float: right;
  }
}

#news-mini .news {
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  border-right: 1px solid #0083B4;
  box-shadow: #16BCF9 1px 0 0 0;
}

@media (max-width: 991px) {
  #news-mini .news {
    border: none;
    box-shadow: none;
  }
}

#news-mini .news .slide:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

#news-mini .news .slide > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
}

#news-mini .news .slide .date {
  color: #004161;
  text-align: center;
}

#news-mini .news .slide .date .day {
  font-size: 30px;
}

#news-mini .news .slide .date .month {
  font-size: 14px;
}

#news-mini .news .slide .desc .title {
  color: #004161;
  margin-bottom: 5px;
  text-transform: uppercase;
  display: inline-block;
  max-width: 98%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

#news-mini .news .slide .desc p {
  color: white;
  font-size: 13px;
  margin: 0;
}

#news-mini .news .slide .desc a {
  display: block;
}

#news-mini .news .slide:focus {
  outline: none;
}

#news-mini .slide-arrow:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

#news-mini .slide-arrow > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
}

#news-mini .slide-arrow .slide-left, #news-mini .slide-arrow .slide-right {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

#news-mini .slide-arrow .slide-left:hover, #news-mini .slide-arrow .slide-right:hover {
  color: white;
}

#news-mini .slide-arrow .slide-left:hover, #news-mini .slide-arrow .slide-right:hover {
  color: white;
}

#news-mini .slide-arrow .slide-right {
  margin-left: 5px;
}

.a-banner {
  margin-top: 30px;
}

#get-quote {
  margin-top: 30px;
}

#event-calendar {
  margin-top: 30px;
}

#home-video-cont {
  padding: 0;
  height: 279px;
}

#home-video-cont a {
  display: block;
  width: 100%;
}

#home-video-cont .vid {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#home-customer-value {
  border-top: 15px solid rgba(255, 255, 255, 0.1);
  border-bottom: 15px solid rgba(0, 0, 0, 0.06);
  padding: 20px 0;
  background-color: #1ca7e0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

#home-customer-value .title {
  color: #004161;
}

#home-customer-value img {
  margin-bottom: 15px;
}

#home-customer-value p {
  color: white;
}

#home-any-last-words {
  margin: 30px 0;
  color: #1ca7e0;
}

#popUpsContainer {
  display: none;
}

.white-popup.popup {
  padding: 10px;
}

#subscribe {
  background: linear-gradient(#ffca01, #f9b418);
  overflow: hidden;
}

@media (max-width: 991px) {
  #subscribe {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

#subscribe img {
  margin: -20px auto;
}

@media (max-width: 991px) {
  #subscribe img {
    margin: auto;
    display: none;
  }
}

#subscribe .text-1 {
  color: #224897;
  margin: 0;
}

@media (max-width: 991px) {
  #subscribe .text-1 {
    margin-top: 15px;
  }
}

#subscribe .text-2 {
  color: #f5f5f5;
  margin: 0;
}

#subscribe #newsletter {
  display: inline-block;
  background-color: #0066b3;
  color: #f5f5f5;
  text-transform: uppercase;
  padding: 18px 32px;
  border-radius: 30px;
  margin-top: 30px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  cursor: pointer;
}

#subscribe #newsletter:hover {
  background-color: #fecc0c;
  color: #0066b3;
}

footer #footer-latar .title {
  color: #224897;
  text-transform: uppercase;
  border-bottom: 2px solid #fecc0c;
  margin-top: 50px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

footer #footer-latar li {
  list-style: none;
  padding-bottom: 15px;
}

footer #footer-latar li a {
  color: #000000;
}

footer #footer-latar li a:hover {
  color: #0066b3;
}

footer #footer-latar .lower-footer {
  padding-top: 65px;
  padding-bottom: 30px;
}

footer #footer-latar .lower-footer .sitemap {
  color: #000000;
  text-transform: uppercase;
  display: inline-block;
}

footer #footer-latar .lower-footer .sitemap a {
  color: #000000;
}

footer #footer-latar .lower-footer .sitemap a:hover {
  color: #000000;
}

footer #footer-latar .lower-footer #newsletter {
  display: inline-block;
  cursor: pointer;
}

footer #footer-latar .lower-footer .logo {
  cursor: pointer;
  display: inline-block;
  margin: 7px;
  border: 2px solid transparent;
  border-radius: 50%;
  background-color: #f5f5f5;
}

footer #footer-latar .lower-footer .logo:hover {
  border-color: #fecc0c;
}

footer #footer-latar #footer-button {
  color: #f5f5f5;
  background: #0066b3;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 17px;
  text-align: center;
  line-height: 43px;
  cursor: pointer;
  display: inline-block;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

footer #footer-latar #footer-button:hover {
  background-color: #fecc0c;
  color: #0066b3;
}

footer #footer-latar #footer-button:before {
  font-family: 'fontAwesome';
  content: '\f077';
}

#social-icon {
  margin-bottom: 5px;
}

#social-icon ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
}

#social-icon ul li {
  font-size: 16px;
  display: inline-block;
}

#social-icon ul li a {
  color: #F3F3F3;
  padding: 0 5px;
}

#social-icon ul li a img {
  max-height: 18px;
  vertical-align: bottom;
}

/*inner page*/
#inner {
  background-color: white;
}

#inner.no-banner {
  margin-top: 0;
}

#inner #newsletter {
  margin: 0 -15px 30px;
  display: block;
  min-height: 100px;
  width: auto;
}

#inner .page-content {
  margin-bottom: 30px;
}

#searchBar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  text-align: center;
  padding-top: 20px;
}

#searchBar input {
  width: 100%;
  height: 100%;
  font-size: 26px;
  text-align: center;
  color: #333;
  border: 0;
  box-shadow: none;
}

.editor-mode [data-block-editable] {
  outline: dashed #7f7f7f 1px;
}

@media (min-width: 768px) {
  #divChangeViews {
    display: none;
  }
}

[data-mh] {
  transition: height 0.3s;
}

#enquiry-popup-div .enquiry-popup-block {
  background: white;
}

#enquiry-popup-div .enquiry-popup-block > form {
  padding: 20px;
}

#enquiry-popup-div .enquiry-popup-block > h4 {
  margin: 20px;
}

#enquiry-popup-div .enquiry-popup-block input[type=text] {
  height: 34px;
}

#enquiry-popup-div .enquiry-popup-block label {
  font-size: 14px;
  font-weight: normal;
}

#enquiry-popup-div .enquiry-popup-block input[type=text], #enquiry-popup-div .enquiry-popup-block .input-group-addon, #enquiry-popup-div .enquiry-popup-block input[type=submit], #enquiry-popup-div .enquiry-popup-block button {
  border-radius: 0;
}

#enquiry-popup-div .enquiry-popup-block hr {
  margin: 20px 0;
}

#enquiry-popup-div .enquiry-popup-block form {
  margin-bottom: 0;
}

#enquiry-popup-div .enquiry-popup-block #sending {
  color: #224897;
  text-transform: uppercase;
}

#facilities {
  margin: 30px 0;
}

#facilities .header {
  color: #224897;
  padding-bottom: 15px;
  margin-bottom: 0;
}

#facilities .header:before {
  content: '';
  position: absolute;
  bottom: 0;
  background-color: #fecc0c;
  height: 2px;
  width: 45px;
}

@media (max-width: 575px) {
  #facilities .header {
    font-size: 30px !important;
  }
}

#facilities .title {
  color: #224897;
  position: relative;
  padding-bottom: 15px;
}

#facilities .title:before {
  content: '';
  position: absolute;
  bottom: 0;
  background-color: #fecc0c;
  height: 2px;
  width: 45px;
}

#facilities .para {
  color: #000000;
}

#facilities .link a {
  color: #0066b3;
  text-transform: uppercase;
  position: relative;
}

#facilities .link a:hover {
  color: #fecc0c;
}

#facilities .link a:after {
  content: '\f054';
  font-family: 'fontAwesome';
  position: absolute;
  top: calc(50% - 7px);
  right: -15px;
}

#facilities .image {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding-bottom: 15px;
  margin-top: 45px;
}

#facilities .image:after {
  content: '';
  position: absolute;
  top: -13px;
  right: -13px;
  width: 50px;
  height: 50px;
  background-color: #0066b3;
  z-index: -1;
}

#faster-safer {
  margin: 90px 0;
}

@media (max-width: 991px) {
  #faster-safer {
    margin: 30px 0;
  }
}

#faster-safer .block {
  background-color: #224897;
  text-align: center;
}

#faster-safer .block .paragraph {
  position: relative;
}

#faster-safer .block .paragraph .image-1 {
  padding: 20px 0;
}

#faster-safer .block .paragraph .title {
  color: #f5f5f5;
  padding: 0 20px;
}

#faster-safer .block .paragraph .text-1 {
  color: #f5f5f5;
  padding: 20px;
  margin-bottom: 20px;
  font-size: 18px;
}

#faster-safer .block .paragraph:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: calc(50% - 22px);
  background-color: #fecc0c;
  height: 2px;
  width: 45px;
}

#faster-safer .block .text-2 {
  color: #f5f5f5;
  width: 69%;
  margin: 0 auto;
  margin-bottom: 20px;
  line-height: 30px;
  font-size: 15px;
}

#faster-safer .block .button {
  display: inline-block;
  background-color: #0066b3;
  color: #f5f5f5;
  text-transform: uppercase;
  padding: 18px 32px;
  border-radius: 30px;
  margin-bottom: 20px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  cursor: pointer;
}

#faster-safer .block .button:hover {
  background-color: #fecc0c;
  color: #0066b3;
}

#faster-safer .image-2 {
  position: absolute;
  max-width: 100%;
  height: 120%;
  top: -10%;
  left: -10%;
  z-index: -1;
}

@media (max-width: 991px) {
  #faster-safer .image-2 {
    max-width: 100%;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    z-index: 0;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  #faster-safer .image-2 {
    height: 100%;
    top: 0;
    left: -15px;
  }
}

#discovery {
  padding-top: 30px;
}

#discovery .header {
  color: #224897;
  padding-bottom: 15px;
  margin-bottom: 0;
  margin-top: -30px;
}

#discovery .header:before {
  content: '';
  position: absolute;
  top: 30px;
  background-color: #fecc0c;
  height: 2px;
  width: 45px;
}

@media (min-width: 576px) and (max-width: 991px) {
  #discovery .header {
    font-size: 30px !important;
  }
  #discovery .header:before {
    top: 60px;
  }
}

@media (min-width: 0px) and (max-width: 575px) {
  #discovery .header {
    font-size: 30px !important;
  }
  #discovery .header:before {
    top: 90px;
  }
}

#discovery .discovery-arrows .slick-prev {
  background: #0066b3;
  position: relative;
  display: inline-block;
  left: 0;
  width: 50px;
  height: 50px;
  margin-top: 110px;
}

#discovery .discovery-arrows .slick-prev:before {
  font-family: 'fontAwesome';
  content: '\f053';
  font-size: 14px;
  line-height: 1;
  color: #f5f5f5 !important;
  opacity: 1 !important;
}

#discovery .discovery-arrows .slick-prev:hover {
  background: #fecc0c;
}

#discovery .discovery-arrows .slick-next {
  background: #0066b3;
  position: relative;
  display: inline-block;
  right: 0;
  width: 50px;
  height: 50px;
  margin-top: 110px;
}

#discovery .discovery-arrows .slick-next:before {
  font-family: 'fontAwesome';
  content: '\f054';
  font-size: 14px;
  line-height: 1;
  color: #f5f5f5 !important;
  opacity: 1 !important;
}

#discovery .discovery-arrows .slick-next:hover {
  background: #fecc0c;
}

#discovery .discovery-wrapper {
  margin-bottom: 0;
}

#discovery .discovery-wrapper .discovery-slide {
  margin-bottom: 30px;
  box-shadow: 0px 10px 30px -10px #000000;
  border: 0 !important;
  width: 100%;
  padding: 30px;
  padding-top: 15px;
  height: 160px;
  position: relative;
}

#discovery .discovery-wrapper .discovery-slide .title-slide {
  color: #f5f5f5;
  position: relative;
  padding-bottom: 15px;
  margin-top: 60px;
  margin-bottom: 0;
}

@media (max-width: 575px) {
  #discovery .discovery-wrapper .discovery-slide .title-slide {
    margin-top: 15px;
  }
}

#discovery .discovery-wrapper .discovery-slide .title-slide:before {
  content: '';
  position: absolute;
  bottom: 0;
  background-color: #f5f5f5;
  height: 2px;
  width: 45px;
}

#discovery .discovery-wrapper .discovery-slide .discovery-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  padding-top: 15px;
  background-color: rgba(254, 204, 12, 0.9);
}

#discovery .discovery-wrapper .discovery-slide .title-overlay {
  display: none;
  color: #f5f5f5;
  position: absolute;
  top: 15px;
  left: 30px;
  opacity: 1;
}

#discovery .discovery-wrapper .discovery-slide .title-overlay:before {
  content: '';
  position: absolute;
  bottom: -15px;
  background-color: #f5f5f5;
  height: 2px;
  width: 45px;
}

#discovery .discovery-wrapper .discovery-slide .content-overlay {
  display: none;
  color: #f5f5f5;
  position: absolute;
  top: 90px;
  left: 30px;
  padding-right: 75px;
  opacity: 1;
}

#discovery .discovery-wrapper .discovery-slide .button-overlay {
  display: none;
  color: rgba(254, 204, 12, 0.9);
  background: #f5f5f5;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 17px;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  bottom: calc(50% - 22px);
}

#discovery .discovery-wrapper .discovery-slide .button-overlay:before {
  font-family: 'fontAwesome';
  content: '\f054';
}

@media (max-width: 575px) {
  #discovery .discovery-wrapper .discovery-slide .button-overlay {
    right: 5px;
    bottom: 5px;
  }
}

#discovery .discovery-wrapper .discovery-slide:hover .title-slide {
  display: none;
}

#discovery .discovery-wrapper .discovery-slide:hover .discovery-overlay {
  display: block;
}

#discovery .discovery-wrapper .discovery-slide:hover .title-overlay {
  display: block;
}

#discovery .discovery-wrapper .discovery-slide:hover .content-overlay {
  display: block;
}

@media (max-width: 575px) {
  #discovery .discovery-wrapper .discovery-slide:hover .content-overlay {
    display: none;
  }
}

#discovery .discovery-wrapper .discovery-slide:hover .button-overlay {
  display: inline-block;
}

#discovery .view-map {
  display: inline-block;
  background-color: #0066b3;
  color: #f5f5f5;
  text-transform: uppercase;
  padding: 18px 32px;
  border-radius: 30px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: absolute;
  right: 0;
  bottom: 5%;
}

#discovery .view-map:hover {
  background-color: #fecc0c;
  color: #0066b3;
}

@media (max-width: 991px) {
  #discovery .view-map {
    position: relative;
  }
}

#corporate-information .text-1 {
  color: #224897;
  padding-bottom: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
  position: relative;
}

#corporate-information .text-1:before {
  content: '';
  position: absolute;
  bottom: 0;
  background-color: #fecc0c;
  height: 2px;
  width: 45px;
}

#corporate-information .text-2 {
  color: #0066b3;
  padding-left: 80px;
  position: relative;
}

#corporate-information .text-2:before {
  font-family: 'fontAwesome';
  content: '\f10d';
  font-size: 30px;
  position: absolute;
  left: 5px;
  top: 0;
  color: #fecc0c;
}

#corporate-information .text-3 {
  color: #224897;
  margin-bottom: 20px;
}

#corporate-information p {
  color: #000000 !important;
  font-family: "ProximaNova-Regular" !important;
  font-size: 16px !important;
}

#corporate-information .text-4 {
  color: #224897;
  padding-bottom: 15px;
  margin-bottom: 15px;
  margin-top: 60px;
  position: relative;
}

#corporate-information .text-4:before {
  content: '';
  position: absolute;
  bottom: 0;
  background-color: #fecc0c;
  height: 2px;
  width: 45px;
}

#corporate-information .block {
  background-color: #f5f5f5;
  padding: 15px;
  margin: 15px 0;
}

#corporate-information .block:hover {
  background-color: #224897;
}

#corporate-information .block:hover .block-right .block-text-1 {
  color: #f5f5f5;
}

#corporate-information .block:hover .block-right .block-text-2 a {
  color: #fecc0c;
}

#corporate-information .block .block-left {
  display: inline-block;
}

#corporate-information .block .block-right {
  display: inline-block;
  padding-left: 15px;
}

#corporate-information .block .block-right .block-text-1 {
  color: #224897;
  margin-bottom: 8px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  #corporate-information .block .block-right .block-text-1 {
    font-size: 15px;
  }
}

#corporate-information .block .block-right .block-text-2 a {
  color: #0066b3;
  text-transform: uppercase;
  position: relative;
}

#corporate-information .block .block-right .block-text-2 a:hover {
  color: #fecc0c;
}

#corporate-information .block .block-right .block-text-2 a:after {
  content: '\f054';
  font-family: 'fontAwesome';
  position: absolute;
  top: calc(50% - 7px);
  right: -15px;
}

#career .header {
  color: #224897;
  padding-bottom: 15px;
  margin-bottom: 15px;
  position: relative;
}

#career .header:before {
  content: '';
  position: absolute;
  bottom: 0;
  background-color: #fecc0c;
  height: 2px;
  width: 45px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  #career .header {
    margin-top: 15px;
  }
}

#career .table {
  margin-bottom: 30px !important;
}

#career .table thead tr td {
  color: #000000;
  text-transform: uppercase;
}

#career .table thead tr td:nth-of-type(3) {
  color: #224897;
  text-transform: uppercase;
}

#career .table tbody tr td {
  color: #000000;
  padding: 3px 8px;
}

#career .detail {
  position: relative;
}

#career .detail .position {
  color: #224897;
  display: inline-block;
}

@media screen and (max-width: 575px) {
  #career .detail .position {
    margin-bottom: 10px;
  }
}

#career .detail .back-to-top {
  color: #224897;
  text-transform: uppercase;
  display: inline-block;
  padding: 0 5px;
  cursor: pointer;
}

@media screen and (max-width: 575px) {
  #career .detail .back-to-top {
    padding: 0;
  }
}

#career .detail .posted {
  color: #224897;
  text-transform: uppercase;
}

#career .detail .description {
  color: #000000 !important;
  font-family: "ProximaNova-Regular" !important;
  font-size: 16px !important;
}

#career .detail .description p {
  color: #000000 !important;
  font-family: "ProximaNova-Regular" !important;
  font-size: 16px !important;
}

#career .detail .enquiry-popup {
  display: inline-block;
  background-color: #0066b3;
  color: #f5f5f5;
  text-transform: uppercase;
  padding: 18px 32px;
  border-radius: 30px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

#career .detail .enquiry-popup:hover {
  background-color: #fecc0c;
  color: #0066b3;
}

@media (max-width: 991px) {
  #career .detail .enquiry-popup {
    position: relative;
    margin-top: 30px;
  }
}

#career .yellow {
  border-bottom: 2px solid #fecc0c !important;
  margin: 30px 0;
}

#announcement-updated {
  margin-top: 30px;
  position: relative;
}

#announcement-updated .announcement {
  position: static;
  padding-top: 30px;
  padding-left: 30px;
}

@media (max-width: 991px) {
  #announcement-updated .announcement {
    position: relative;
    padding: 30px;
    margin-bottom: 30px;
  }
}

#announcement-updated .announcement:before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 50%;
  background: #224897;
  background-size: 100%;
  z-index: -1;
}

@media (max-width: 991px) {
  #announcement-updated .announcement:before {
    width: 100%;
    height: 100%;
  }
}

#announcement-updated .announcement .header {
  color: #f5f5f5;
  padding-bottom: 15px;
  margin-bottom: 40px;
  position: relative;
}

#announcement-updated .announcement .header:before {
  content: '';
  position: absolute;
  bottom: 0;
  background-color: #fecc0c;
  height: 2px;
  width: 45px;
}

@media (max-width: 575px) {
  #announcement-updated .announcement .header {
    font-size: 30px !important;
  }
}

#announcement-updated .announcement .block {
  padding-right: 110px;
  margin-bottom: 30px;
  position: relative;
}

#announcement-updated .announcement .block .fa {
  font-size: 18px;
  margin-right: 5px;
}

#announcement-updated .announcement .block .date {
  color: #f5f5f5;
  padding-bottom: 5px;
}

#announcement-updated .announcement .block .title {
  color: #f5f5f5;
}

#announcement-updated .announcement .block .title a {
  color: #f5f5f5;
  text-transform: uppercase;
  line-height: 25px;
}

@media (max-width: 575px) {
  #announcement-updated .announcement .block {
    padding-right: 0;
  }
}

#announcement-updated .announcement .block .button {
  color: #f5f5f5;
  background: #224897;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 17px;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
  display: inline-block;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: calc(50% - 22px);
  right: 5%;
}

#announcement-updated .announcement .block .button:hover {
  background-color: #fecc0c;
  color: #224897;
}

#announcement-updated .announcement .block .button:before {
  font-family: 'fontAwesome';
  content: '\f054';
}

@media (max-width: 575px) {
  #announcement-updated .announcement .block .button {
    position: relative;
  }
}

#announcement-updated .announcement .view-all {
  display: inline-block;
  background-color: #0066b3;
  color: #f5f5f5;
  text-transform: uppercase;
  padding: 18px 32px;
  border-radius: 30px;
  margin-top: 10px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#announcement-updated .announcement .view-all:hover {
  background-color: #fecc0c;
  color: #0066b3;
}

#announcement-updated .update {
  position: static;
  padding-top: 30px;
  padding-left: 30px;
}

@media (max-width: 991px) {
  #announcement-updated .update {
    position: relative;
    padding: 30px;
    margin-bottom: 30px;
  }
}

#announcement-updated .update:before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  width: 50%;
  background: #f5f5f5;
  background-size: 100%;
  z-index: -1;
}

@media (max-width: 991px) {
  #announcement-updated .update:before {
    left: 0;
    width: 100%;
    height: 100%;
  }
}

#announcement-updated .update .header-switch {
  position: relative;
}

#announcement-updated .update .header-switch .header {
  color: #224897;
  padding-bottom: 15px;
  margin-bottom: 40px;
  position: relative;
}

#announcement-updated .update .header-switch .header:before {
  content: '';
  position: absolute;
  bottom: 0;
  background-color: #fecc0c;
  height: 2px;
  width: 45px;
}

@media (max-width: 575px) {
  #announcement-updated .update .header-switch .header {
    font-size: 30px !important;
  }
}

#announcement-updated .update .social-media {
  height: 530px;
}

#announcement-updated .update .social-media .facebook {
  width: 100%;
  height: 500px;
  border: none;
  overflow: hidden;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#announcement-updated .update .social-media .twitter {
  height: 500px;
  width: auto;
  overflow-x: hidden;
  overflow-y: scroll;
  display: none;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.switch {
  position: absolute;
  display: inline-block;
  width: 60px;
  height: 34px;
  top: 0;
  right: 0;
  cursor: pointer;
}

@media (max-width: 575px) {
  .switch {
    top: 100%;
  }
}

.switch input {
  display: none;
}

.switch input:checked + .slider {
  background-color: #00aced;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #00aced;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3d5a96;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch .slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #f5f5f5;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch.on:before {
  color: #f5f5f5;
}

.switch.on:after {
  color: #00aced;
}

.switch.on:hover:before {
  color: #3d5a96;
}

.switch:before {
  font-family: 'fontAwesome';
  content: "\f09a";
  position: absolute;
  z-index: 9999;
  top: 8px;
  left: 12px;
  font-size: 18px;
  color: #3d5a96;
}

.switch:after {
  font-family: 'fontAwesome';
  content: "\f099";
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 18px;
  color: #f5f5f5;
}

.switch:hover:after {
  color: #00aced;
}

#mobile-menu .mm-panel {
  background-color: #224897;
  color: #f5f5f5;
}

#mobile-menu .mm-title {
  color: #f5f5f5;
}

#mobile-menu .mm-listview > li .mm-next:after, #mobile-menu .mm-menu .mm-listview > li .mm-arrow:after {
  border-color: #f5f5f5;
}

#mobile-menu .mm-navbar .mm-btn:before, #mobile-menu .mm-menu .mm-navbar .mm-btn:after {
  border-color: #f5f5f5;
}

.rc-anchor-aria-status {
  display: block;
  position: absolute;
  bottom: 6px;
  font-family: Roboto,helvetica,arial,sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
  padding-left: 12px;
  color: red;
}

#contactContainer .contact-information-section {
  margin-top: 15px;
}

#contactContainer .contact-information-section:nth-child(1) {
  margin-top: 0px;
}

#contactContainer .contact-information-section .contact-information-section-title {
  color: #224897;
}

#contactContainer .contact-information-section .contact-information-section-description {
  color: #000000;
}

#contactContainer .contact-information-section .contact-information-section-extra {
  color: #000000;
}

.boa-block .boa-link {
  color: #224897;
}

.boa-block .boa-link-2 {
  color: #000000;
}

.boa-block:hover .boa-link, .boa-block:hover .boa-link-2 {
  color: #fecc0c;
}
