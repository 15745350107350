.buffer-xs-0{
  margin-top: 0;
}
.buffer-xs-10{
  margin-top: 10px;
}
.buffer-xs-20{
  margin-top: 20px;
}
@media(min-width: 768px){
  .buffer-sm-0{
    margin-top: 0;
  }
  .buffer-sm-10{
    margin-top: 10px;
  }
}
@media(min-width: 992px){
  .buffer-md-0{
    margin-top: 0;
  }
  .buffer-md-10{
    margin-top: 10px;
  }
}