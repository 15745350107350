$mediaElementAssetsDir: '../../assets/mediaelement';

.mejs-overlay-button {
  background-image: url('#{$mediaElementAssetsDir}/bigplay.svg');
}

.no-svg .mejs-overlay-button {
  background-image: url('#{$mediaElementAssetsDir}/bigplay.png');
}

.mejs-overlay-loading {
  background-image: url('#{$mediaElementAssetsDir}/background.png');

  span{
    background-image: url('#{$mediaElementAssetsDir}/loading.gif');
  }
}

.mejs-container .mejs-controls,
.mejs-controls .mejs-volume-button .mejs-volume-slider,
.mejs-controls .mejs-captions-button .mejs-captions-selector,
.mejs-captions-text,
.mejs-controls .mejs-sourcechooser-button .mejs-sourcechooser-selector,
.mejs-postroll-layer,
.mejs-postroll-close,
.mejs-controls .mejs-speed-button .mejs-speed-selector
{
  background-image: url('#{$mediaElementAssetsDir}/background.png');
}

.mejs-controls .mejs-button button {
  background-image: url('#{$mediaElementAssetsDir}/controls.svg');
}

.no-svg .mejs-controls .mejs-button button {
  background-image: url('#{$mediaElementAssetsDir}/controls.png');
}

.mejs-controls .mejs-button.mejs-skip-back-button{
  background-image: url('#{$mediaElementAssetsDir}/skipback.png');
}