@mixin uno-divider($left: 0, $right: 0, $top: 0, $bottom: 0, $blurX: 0, $blurX: 0, $shadow: #151515, $highlight: $minor-white) {
  $left-shadow: null;
  $right-shadow: null;
  $top-shadow: null;
  $bottom-shadow: null;
  @if $left > 0 {
    border-left: $left solid $shadow;
    $left-shadow: $highlight (-$left) 0 $blurX $blurX;
  }
  @if $right > 0 {
    border-right: $right solid $shadow;
    $right-shadow: $highlight $right 0 $blurX $blurX;
  }
  @if $top > 0 {
    border-top: $top solid $shadow;
    $top-shadow: $highlight 0 (-$top) $blurX $blurX;
  }
  @if $bottom > 0 {
    border-bottom: $bottom solid $shadow;
    $bottom-shadow: $highlight 0 $bottom $blurX $blurX;
  }
  box-shadow: $left-shadow, $right-shadow, $top-shadow, $bottom-shadow;
}

@mixin hover-blue {
  @include transition(all 0.5s ease-in-out);
  &:hover {
    @extend %highlight-color;
  }
}

@mixin hover-color($color: white) {
  @include transition(all 0.5s ease-in-out);
  &:hover {
    color: $color;
  }
}

@mixin hover-background-blue {
  @include transition(all 0.5s ease-in-out);
  &:hover {
    @extend %highlight-background;
  }
}

@mixin no-listing {
  padding: 0;
  margin: 0;
  list-style: none;
}

@mixin vertical-center {
  display: inline-block;
  vertical-align: middle;
}

@mixin arrow-gen($direction: top, $size: 10px, $color: #ccc, $center: 50%, $margin: 0, $pseudo: before) {
  position: relative;
  &:#{$pseudo} {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-color: $color;

    @if $direction == "right" {
      top: $center;
      left: 100%;
      margin-left: $margin;
      margin-top: $size * -1;
      border-top: $size solid transparent;
      border-bottom: $size solid transparent;
      border-left: $size solid $color;
      border-left-color: inherit;
    } @else if $direction == "down" {
      top: 100%;
      left: $center;
      margin-top: $margin;
      margin-left: $size * -1;
      border-left: $size solid transparent;
      border-right: $size solid transparent;
      border-top: $size solid $color;
      border-top-color: inherit;
    } @else if $direction == "left" {
      top: $center;
      right: 100%;
      margin-right: $margin;
      margin-top: $size * -1;
      border-top: $size solid transparent;
      border-bottom: $size solid transparent;
      border-right: $size solid $color;
      border-right-color: inherit;
    } @else {
      bottom: 100%;
      left: $center;
      margin-bottom: $margin;
      margin-left: $size * -1;
      border-left: $size solid transparent;
      border-right: $size solid transparent;
      border-bottom: $size solid $color;
      border-bottom-color: inherit;
    }
  }
}

@mixin centering-the-unknown($offset: null) {
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    @if $offset != null {
      margin-right: $offset;
    }
  }
  & > * {
    display: inline-block;
    vertical-align: middle;
    max-width: 98%;
  }
}

@mixin last-child-margin($bottom: 30px) {
  & > *:last-child {
    margin-bottom: $bottom;
  }
}