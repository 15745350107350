.bannerMfp {
  .mfp-iframe {
    background: none;
    box-shadow: none;
  }
}

.mfp-bg {
  z-index: 999998;
}

.mfp-wrap {
  z-index: 999999;
}

.uno-mfp-popup {
  max-width: 600px;
  &.white {
    background-color: white;
    .mfp-close {
      color: black;
      font-size: 22px;
    }
    > .box {
      border-radius: 0;
    }
  }
  margin: 10px auto;
  color: #000;
  font-family: helvetica, arial, sans-serif;
  position: relative;

  > .popup-container {
    padding: 20px;
    > h4 {
      margin: 0;
    }
  }
}

.mfp-iframe-holder .mfp-content {
  max-width: 1200px;
}

/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.mfp-close-btn-in .mfp-close {
  color: #FFF;
}