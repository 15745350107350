/* columns of same height styles */

.row-full-height {
  height: 100%;
}
.col-full-height {
  height: 100%;
  vertical-align: middle;
}
.row-same-height {
  display: table;
  table-layout: fixed;
}
/*revert back to normal in md*/
.row-md-same-height {
  display: table;
  table-layout: fixed;
  @media (max-width: 992px) {
    display: block;
  }
}
.row-sm-same-height {
  display: table;
  table-layout: fixed;
  @media (max-width: 768px) {
    display: block;
  }
}
.col-xs-height {
  display: table-cell;
  float: none !important;
}
/*alignments*/
.col-top {
  vertical-align: top;
}
.col-middle {
  vertical-align: middle;
}
.col-bottom {
  vertical-align: bottom;
}
//@include responsive($big-break) {  }
@media (min-width: 768px) {
  .col-sm-height {
    display: table-cell;
    float: none !important;
  }
}
@media (min-width: 992px) {
  .col-md-height {
    display: table-cell;
    float: none !important;
  }
}
@media (min-width: 1000px) {
  .col-lg-height {
    display: table-cell;
    float: none !important;
  }
}

//

%v-align {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

%h-align {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

%vh-align {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

%background_img {
  background: {
    size: cover !important;
    position: center !important;
    repeat: no-repeat !important;
  }
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie);
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
}

@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

/* columns of same height styles */

.row-full-height {
  height: 100%;
}

.col-full-height {
  height: 100%;
  vertical-align: middle;
}

.row-same-height {
  display: table;
  table-layout: fixed;
  width: 100%;
  vertical-align: middle;
}

/*revert back to normal in md*/
.row-md-same-height {
  @extend .row-same-height;
  @media (max-width: 991px) {
    display: block;
  }
}

.row-sm-same-height {
  @extend .row-same-height;
  @media (max-width: 767px) {
    display: block;
  }
}

.col-xs-height {
  display: table-cell;
  float: none !important;
}

/*alignments*/
.col-top {
  vertical-align: top;
}

.col-middle {
  vertical-align: middle;
}

.col-bottom {
  vertical-align: bottom;
}

//@include responsive($big-break) {  }
@media (min-width: 767px) {
  .col-sm-height {
    display: table-cell;
    float: none !important;
  }
}

@media (min-width: 991px) {
  .col-md-height {
    display: table-cell;
    float: none !important;
  }
}

@media (min-width: 1000px) {
  .col-lg-height {
    display: table-cell;
    float: none !important;
  }
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
  & [class^="col-"], & [class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.row-centered {
  text-align: center;
  .col-centered {
    display: inline-block;
    float: none;
    vertical-align: top;
    text-align: left;
    margin-left: -4px;
  }
}

.v-align-bottom {
  display: table-cell;
  float: none !important;
  vertical-align: bottom;
}

.col-xs-1-5, .col-sm-1-5, .col-md-1-5, .col-lg-1-5, .col-xs-2-5, .col-sm-2-5, .col-md-2-5, .col-lg-2-5, .col-xs-3-5, .col-sm-3-5, .col-md-3-5, .col-lg-3-5, .col-xs-4-5, .col-sm-4-5, .col-md-4-5, .col-lg-4-5 {
  position: relative;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
  @media (max-width: 991px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.col-xs-1-5 {
  width: 20%;
  float: left;
}

.col-xs-2-5 {
  width: 40%;
  float: left;
}

.col-xs-3-5 {
  width: 60%;
  float: left;
}

.col-xs-4-5 {
  width: 80%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-1-5 {
    width: 20%;
    float: left;
  }
  .col-sm-2-5 {
    width: 40%;
    float: left;
  }
  .col-sm-3-5 {
    width: 60%;
    float: left;
  }
  .col-sm-4-5 {
    width: 80%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-1-5 {
    width: 20%;
    float: left;
  }
  .col-md-2-5 {
    width: 40%;
    float: left;
  }
  .col-md-3-5 {
    width: 60%;
    float: left;
  }
  .col-md-4-5 {
    width: 80%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-1-5 {
    width: 20%;
    float: left;
  }
  .col-lg-2-5 {
    width: 40%;
    float: left;
  }
  .col-lg-3-5 {
    width: 60%;
    float: left;
  }
  .col-lg-4-5 {
    width: 80%;
    float: left;
  }
}

//

%background_img {
  background: {
    size: cover !important;
    position: center !important;
    repeat: no-repeat !important;
  }
}

.m-left {
  text-align: left;
}
.m-center {
  text-align: center;
}
.m-right {
  text-align: right;
}

@media (max-width: 9999px) {
  .m-lg-left {
    text-align: left;
  }
  .m-lg-center {
    text-align: center;
  }
  .m-lg-right {
    text-align: right;
  }
}

@media (max-width: 1199px) {
  .m-md-left {
    text-align: left;
  }
  .m-md-center {
    text-align: center;
  }
  .m-md-right {
    text-align: right;
  }
}

@media (max-width: 991px) {
  .m-sm-left {
    text-align: left;
  }
  .m-sm-center {
    text-align: center;
  }
  .m-sm-right {
    text-align: right;
  }
}

@media (max-width: 767px) {
  .m-xs-left {
    text-align: left;
  }
  .m-xs-center {
    text-align: center;
  }
  .m-xs-right {
    text-align: right;
  }
}

@media (max-width: 575px) {
  .m-xx-left {
    text-align: left;
  }
  .m-xx-center {
    text-align: center;
  }
  .m-xx-right {
    text-align: right;
  }
}

//