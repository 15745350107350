//**
// Typography
//**
%f01 { font-size: 12px; font-family: "Open Sans", sans-serif;}
%f02 { font-size: 14px; font-family: Helvetica Neue, Helvetica, Arial, sans-serif;}
%f03 { font-size: 12px; font-family: "Open Sans", sans-serif; font-weight: bold;}

body {
  @extend %f01;
  line-height: 1.2;
}

blockquote {
  border-left: 2px solid $primary-color;
  font-family: Georgia, Times, "Times New Roman", serif;
  font-size: 1.4em;
  font-style: italic;
  margin: 0 0 $padding/2;
  padding-left: 25px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Open Sans", sans-serif;
  line-height: 1;
  font-weight: bold;
}

h1 {
  font-size: 42px;
  font-style: normal;
  margin: 0 0 25px;
}

h2 {
  font-size: 30px;
  font-style: normal;
  margin: 0 0 25px;
}

h3 {
  font-size: 24px;
  font-style: normal;
  margin: 0 0 25px;
}

h4 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin: 0 0 25px;
}

h5 {
  font-size: 16px;
  font-style: normal;
  margin: 0 0 25px;
}

p {
  margin: 0 0 $padding/2; font-size: 12px;
}

pre {
  background: #222;
  color: #fff;
  display: block;
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 13px;
  line-height: 20px;
  margin: 0 0 $padding/2;
  padding: 10px;
  word-break: break-all;
  word-wrap: break-word;
}

code {
  border-radius: 5px;
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, .1);
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 13px;
  line-height: 20px;
  padding: 2px 10px;
  word-break: break-all;
  word-wrap: break-word;
}

a {
  color: $primary-color;
  display: inline-block;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}




/*

// Responsive styles if needed
// @include responsive($big-break) {
// }
@include responsive($small-break) {
  body {
    font-size: 14px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 10px;
  }

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h4,
  h5,
  p,
  ul,
  ol {
    font-size: 14px;
  }
}
*/

// Mobile first styles if needed
// @include mobilefirst($big-break) {
// }
// @include mobilefirst($small-break) {
// }

%f-a {
  font-family: $proximanova-regular;
  font-size: 12px;
}

%f-b {
  font-family: $proximanova-bold;
  font-size: 16px;
}

%f-c {
  font-family: $proximanova-regular;
  font-size: 16px;
}

%f-d {
  font-family: $proximanova-regular;
  font-size: 38px;
}

%f-e {
  font-family: $proximanova-bold;
  font-size: 38px;
}

%f-f {
  font-family: $proximanova-bold;
  font-size: 13px;
}

%f-g {
  font-family: $proximanova-regular;
  font-size: 14px;
}

%f-h {
  font-family: $proximanova-bold;
  font-size: 28px;
}

%f-i {
  font-family: $proximanova-regular;
  font-size: 18px;
}

%f-j {
  font-family: $proximanova-regular;
  font-size: 28px;
}

%f-k {
  font-family: $proximanova-regular;
  font-size: 30px;
}

%f-l {
  font-family: $proximanova-regular;
  font-size: 11px;
}

%f-m {
  font-family: $proximanova-regular;
  font-size: 32px;
}

%f-n {
  font-family: $proximanova-bold;
  font-size: 34px;
}

%f-o {
  font-family: $proximanova-bold;
  font-size: 22px;
}

%f-p {
  font-family: $proximanova-bold;
  font-size: 13px;
}
