/* Bourbon */
@import "../../../vendor/bower_components/bourbon/app/assets/stylesheets/bourbon";

//Mixins
@import 'mixins';

//Bootstrap buffers
@import 'buffers';

//Fixes
@import "fixes/iCheck";
@import "fixes/magnific-popup";
@import "fixes/select2";
@import "fixes/slick";
@import "fixes/tinyMCE";
@import "fixes/mediaelement";
@import "fixes/jstree";

/* No bold */
.no-bold{
  font-weight: normal;
}

/* IE Image Fix */
img {
  -ms-interpolation-mode: bicubic;
}

/* Easy background image */
.bg-img{
  background: no-repeat center;
  &.bg-cover{
    background-size: cover;
  }
  &.bg-contain{
    background-size: contain;
  }
}

/* Button Block Text Overflow */
.btn-block{
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Hide CSRF Token */
#_token{
  display: none;
}

/* Bootstrap: Remove gutter */
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

/* Remove Transition */
.no-transition {
  transition: none !important;
}

a.block{
  display: block;
}

.btn:focus {
  outline: none;
}