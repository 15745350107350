@mixin opacity($opacity) {
  opacity: $opacity;
  // IE8 filter
  $opacity-ie: ($opacity * 100);
  filter: alpha(opacity=$opacity-ie);
}

// Clear after floats
@mixin clearfix {
  zoom: 1;

  &:before,
  &:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden;
  }

  &:after {
    clear: both;
  }
}

// Apply clearfix to this classes by default
.clearfix,
.group {
  @include clearfix;
}

// Responsive mixins
@mixin responsive($res) {
  @media screen and (max-width: $res) {
    @content;
  }
}

@mixin mobilefirst($res) {
  @media screen and (min-width: $res) {
    @content;
  }
}
